@import "../../main/scss/mixin";
@import "sprite.scss";

#footer {
	margin-top: -1px;
	.section__background {
		img {
			width: 100%;
			height: 100%;
		}
		@include mobile {
			height: 418px;
		}
	}

	.section__content {
		@include mobile {
			height: 418px;
		}
		.social-link-container {
			display: flex;
			justify-content: center;
			gap: 48px;
			margin-top: 54px;

			@include mobile {
				gap: 0;
				justify-content: space-evenly;
			}

			a {
				@include spriteImg;
				@include hoverLight(200%);
				transition: filter 200ms ease-in-out;
			}

			.facebook {
				@include sprite($facebook);
				@include mobile {
					@include sprite($facebook-mb);
				}
			}
			.group {
				@include sprite($group);
				@include mobile {
					@include sprite($group-mb);
				}
			}
			.tiktok {
				@include sprite($tiktok);
				@include mobile {
					@include sprite($tiktok-mb);
				}
			}
			.discord {
				@include sprite($discord);
				@include mobile {
					@include sprite($discord-mb);
				}
			}
			.youtube {
				@include sprite($youtube);
				@include mobile {
					@include sprite($youtube-mb);
				}
			}
			.instagram {
				@include sprite($instagram);
				@include mobile {
					@include sprite($instagram-mb);
				}
			}
		}

		.title-text {
			@include hoverLight(150%);
			transition: filter 200ms ease-in-out;
			font-family: roboto;
			font-weight: 400;
			font-size: 18px;
			color: #78756d;
			position: absolute;
			top: 271px;
			left: 50%;
			translate: -50% 0;
			width: 206px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			cursor: pointer;
			@include mobile {
				top: 196px;
			}
		}

		.logo {
			@include sprite($logo);
			position: absolute;
			top: 340px;
			left: 50%;
			translate: -50% 0;

			@include mobile {
				top: 260px;
			}
		}

		.copyright-text {
			font-family: barlow;
			font-weight: 400;
			font-size: 18px;
			color: #a0927f;
			position: absolute;
			top: 391px;
			left: 50%;
			translate: -50% 0;
			text-align: center;
			@include mobile {
				top: 313px;
			}
		}
	}
}

.th #footer {
	.section__content {
		.title-text {
		}

		.copyright-text {
			font-family: sukhumvitset;
			font-weight: 400;
			font-size: 20px;
		}
		.social-link-container {
			.group {
				@include sprite($group-th);
				@include mobile {
					@include sprite($group-th-mb);
				}
			}
		}
	}
}

.tw #footer {
	.section__background {
		@include mobile {
			height: 573px;
		}
	}
	.section__content {
		@include mobile {
			height: 573px;
		}

		.title-text {
			top: 272px;
			font-size: 30px;

			@include mobile {
				top: 200px;
			}
		}

		.logo {
			@include sprite($logo);
			position: absolute;
			top: 333px;
			left: 50%;
			translate: -50% 0;

			@include mobile {
				top: 271px;
			}
		}

		.social-link-container {
			.group {
				@include sprite($group-tw);
				@include mobile {
					@include sprite($group-tw-mb);
				}
			}
		}
		.copyright-text {
			top: 387px;

			@include mobile {
				top: 320px;
			}
		}

		.container15 {
			position: absolute;
			bottom: 58px;
			left: 558px;
			display: flex;
			gap: 45px;
			align-items: center;
			@include mobile {
				left: 26px;
				gap: 12px;
			}
			.img15 {
				@include sprite($age);
				@include mobile {
					@include sprite($age-mb);
				}
				flex-shrink: 0;
			}

			.text {
				flex-shrink: 0;
				font-family: chineseH;
				color: #fff;
				font-size: 24px;
				width: 100%;

				@include mobile {
					font-size: 20px;
					max-width: 583px;
				}
			}
		}
	}
}

.vn #footer {
	.section__background {
		@include mobile {
			height: 573px;
		}
	}
	.section__content {
		@include mobile {
			height: 573px;
		}

		.title-text {
			top: 270px;

			@include mobile {
				top: 202px;
			}
		}

		.logo {
			@include sprite($logo);
			position: absolute;
			bottom: 310px;
			left: 50%;
			translate: -50% 0;

			@include mobile {
				bottom: 271px;
			}
		}

		.copyright-text {
			top: 393px;
			width: 90%;
			@include mobile {
				top: 325px;
			}
		}
	}
}
