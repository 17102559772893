@import "../../main/scss/mixin";
@import "sprite.scss";

#block5 {
	margin-top: -1px;
	.section__background {
		img {
			width: 100%;
			height: 100%;
		}
		// .diag-top {
		// 	@include sprite($diag-top);
		// 	@include mobile {
		// 		@include sprite($diag-top-mb);
		// 	}
		// }
		// .diag-bot {
		// 	@include sprite($diag-bot);
		// 	@include mobile {
		// 		@include sprite($diag-bot-mb);
		// 	}
		// }

		// .man {
		// 	@include sprite($man);
		// 	position: absolute;
		// 	bottom: 0;
		// 	left: 0;
		// 	z-index: 3;
		// }
	}

	.section__content {
		.title {
			position: absolute;
			top: 183px;
			left: 50%;
			translate: -50% 0;

			@include mobile {
				top: 138px;
			}
		}
		.block5-swiper-container {
			display: flex;
			justify-content: center;
			position: absolute;
			bottom: 0;
			left: 50%;
			translate: -50% 0;
			z-index: 10;
			width: 2000px;
			height: 700px;
			overflow: visible;

			@include mobile {
				width: var(--width-mobile);
				height: 439px;
			}

			#hot-feature-swiper {
				width: 64%;
				padding-left: 92px;
				position: relative;
				left: 41px;
				@include mobile {
					width: 80%;
					padding-left: 20px;
					left: 0;
				}
				.swiper-slide {
					width: 872px !important;
					height: 565px !important;

					@include mobile {
						width: 511px !important;
						height: 331px !important;
					}

					.img-bg {
						background-image: url("../images/slide-bg.png");
						width: 100%;
						height: 100%;
						background-size: 100% 100%;

						.img-container {
							position: absolute;
							width: 800px;
							height: 500px;
							top: 44px;
							left: 30px;
							display: flex;
							justify-content: center;
							align-items: center;

							@include mobile {
								width: 470px;
								height: 293px;
								top: 26px;
								left: 17px;
							}

							img {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								object-fit: cover;
								z-index: 0;
							}
							.btn-play {
								@include sprite($btn-play);
								@include spriteImg;
								@include hoverLight;
								transition: filter 200ms ease-in-out;
								position: relative;
								z-index: 1;
							}
						}
					}
				}

				.swiper-pagination {
					@include mobile {
						bottom: 50px;
					}

					&-bullet {
						@include sprite($pagination);
						@include hoverLight(120%);
						transition: filter 200ms ease-in-out;
						background-color: transparent;
						border-radius: 0;
						opacity: 1;
						@include mobile {
							margin-right: 24px;
						}
						&-active {
							@include sprite($pagination-selected);
						}
					}
				}
			}

			.swiper-button {
				&::after {
					display: none;
				}
				&-prev {
					@include sprite($swiper-prev);
					right: unset;
					bottom: unset;
					left: 300px;
					top: 50%;
					translate: 0 -50%;

					@include mobile {
						@include sprite($swiper-prev-mb);
						left: 10px;
						top: -20px;
						bottom: unset;
						right: unset;
					}
				}

				&-next {
					@include sprite($swiper-next);
					left: unset;
					bottom: unset;
					right: 223px;
					top: 50%;
					translate: 0 -50%;

					@include mobile {
						@include sprite($swiper-next-mb);
						left: unset;
						bottom: unset;
						right: 10px;
						top: -20px;
						bottom: unset;
					}
				}
			}
		}
	}
}

.tw #block5 {
	.title {
		top: 153px;
	}
}
