@import 'mixin';

:root {
	--width-desktop: 2000px;
	--width-mobile: 768px;
	--height-desktop: 1000px;
	--height-mobile: 1000px;
	--popup-height: 900px;
	//--font-primary: Barlow;
	//--font-secondary: Barlow;
	--fontsize-primary-desktop: 18px;
	--fontsize-primary-mobile: 24px;
	--fontheight-primary: 1.4;
	--fontweight-heavy: 900;
	--fontweight-bold: 700;
	--fontweight-semibold: 600;
	--fontweight-medium: 500;
	--fontweight-normal: 400;
	--fontweight-light: 300;
}

* {
	box-sizing: border-box;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	text-decoration: none;
	//touch-action: none;
	//text-align: justify;
	&:focus {
		outline: none;
	}
}

html {
	@include scrollBar(transparent, #ad7d56);
	overflow-x: hidden;
	overflow-y: auto;
	background: #fdfdfd;
	scroll-behavior: smooth;
	&.popup-opened {
		& {
			position: relative;
			height: 100% !important;
			overflow: hidden;
			touch-action: none;
			-ms-touch-action: none;
		}
	}
}

body {
	font-family: var(--font-primary), var(--font-secondary), sans-serif;
	line-height: var(--fontheight-primary);
	font-size: var(--fontsize-primary);

	width: 100%;
	// height: 100vh;
	overflow-x: hidden;
	overflow-y: hidden;
	margin: 0;
	padding: 0;
	background: none;
	position: relative;
	// overflow: hidden;
	// overflow-x: hidden;

	&.popup-opened {
		overflow: hidden;
	}
}

button {
	cursor: pointer;
	border: none;
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
}

a {
	cursor: pointer;
	display: block;
	text-decoration: none;
}

input {
	border: none;
	background-color: transparent;
}


ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

p {
	margin: 0;
}