@mixin image($folder, $filename, $width: auto, $height: auto) {
	background-image: url("../images/#{$folder}/#{$filename}");
	background-repeat: no-repeat;
	width: $width;
	height: $height;
}

@mixin mobile {
	@media (orientation: portrait) and (max-width: 998px) {
		@content;
	}
}

@mixin scrollBar($bgColor, $thumbColor) {
	// firefox
	scrollbar-color: $thumbColor $bgColor;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		margin-right: 6px;
		width: 6px;
		transition: width 0.2s;
	}

	&:hover {
		&::-webkit-scrollbar {
			width: 6px;
		}
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: rgba($bgColor, 0.5);
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: rgba($thumbColor, 1);
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: rgba($thumbColor, 1);
	}
}

@mixin spriteBg {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin spriteImg {
	font-size: 0;
	@include spriteBg;
}

@mixin hoverLight($percentage: 110%) {
	&:hover {
		filter: brightness($percentage);
	}
}

@mixin hoverDark($percentage: 70%) {
	&:hover {
		filter: grayscale($percentage);
	}
}

@mixin hoverBig($scaleAmount: 1.1) {
	&:hover {
		scale: $scaleAmount;
	}
}

@mixin textBorder($width, $color) {
	text-shadow: #{$width} 0 0 $color, #{-1 * $width} 0 0 $color, 0 #{$width} 0 $color, 0 #{-1 * $width} 0 $color,
		#{$width} #{$width} 0 $color, #{-1 * $width} #{-1 * $width} 0 $color, #{-1 * $width} #{$width} 0 $color,
		#{$width} #{-1 * $width} 0 $color, #{calc(1 / 2) * $width} #{calc(1 / 2) * $width} 0 $color,
		#{calc(-1 / 2) * $width} #{calc(-1 / 2) * $width} 0 $color,
		#{calc(1 / 2) * $width} #{calc(-1 / 2) * $width} 0 $color,
		#{calc(-1 / 2) * $width} #{calc(1 / 2) * $width} 0 $color;
}
