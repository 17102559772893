@use "../../main/scss/mixin" as *;
@import "sprite.scss";

#block1 {
  margin-top: 70px;
  @include mobile {
    margin-top: 40px;
  }

  .section__background {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .section__content {
    .title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 570px;

      @include mobile {
        //left: 50%;
        top: unset;
        bottom: 318px;
        //translate: -50% 0;
      }

      .tooltip {
        @include sprite($tooltip);
        position: absolute;
        top: -92px;
        right: 118px;

        @include mobile {
          right: 152px;
          top: -102px;
        }
      }
    }

    .store-bg {
      background-image: url("../images/store-bg.png");
      width: 790px;
      height: 192px;
      position: absolute;
      bottom: 20px;
      left: 604px;

      .game-icon {
        @include sprite($game-icon);
        transition: filter 200ms ease-in-out;
        position: absolute;
        left: 45px;
        top: 50%;
        translate: 0 -50%;
        border: 3px solid white;
        border-radius: 30px;
      }

      .download-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50%;
        left: 233px;
        translate: 0 -50%;
        background-color: #7a3a27;
        padding: 5px;
        gap: 4px;
      }

      .link {
        @include spriteImg;
        @include hoverLight();
        transition: filter 200ms ease-in-out;

        &-appstore {
          @include sprite($appstore-en);
        }

        &-googleplay {
          @include sprite($googleplay-en);
        }

        &-apk {
          @include sprite($apk-en);
        }

        &-code {
          @include sprite($code-en);
          position: absolute;
          top: 50%;
          right: 47px;
          translate: 0 -50%;
        }

        &-topup {
          @include sprite($topup-en);
          position: absolute;
          top: 50%;
          right: 209px;
          translate: 0 -50%;
        }
      }
    }

    .btn-download {
      @include sprite($btn-download);
      position: absolute;
      left: 50%;
      bottom: 192px;
      translate: -50% 0;
      align-items: center;
      justify-content: center;
      gap: 16px;
      color: black;
      font-family: roboto;
      font-weight: 700;
      font-size: 37.82px;

      @include mobile {
        display: flex !important;
      }

      .arrow-icon {
        display: block;
        @include sprite($arrow-icon);
      }
    }
  }
}

.vn #block1 {
  .section__content {
    .link {
      &-code {
        @include sprite($code-vi);
      }

      &-topup {
        @include sprite($topup-vi);
      }
    }

    @include mobile {
      .title {
        bottom: 150px;
      }
      .btn-download {
        bottom: 30px;
      }
    }
  }
}

.id #block1 {
  .section__content {
    .link {
      &-code {
        @include sprite($code-id);
      }
    }
  }
}

.th #block1 {
  .section__content {
    .link {
      &-appstore {
        @include sprite($appstore-th);
      }

      &-googleplay {
        @include sprite($googleplay-th);
      }

      &-apk {
        @include sprite($apk-th);
      }

      &-code {
        @include sprite($code-th);
      }

      &-topup {
        @include sprite($topup-th);
      }
    }

    .btn-download {
      font-family: sukhumvitset;
      font-weight: 700;
      font-size: 48px;
    }
  }
}

.tw #block1 {
  .section__content {
    .link {
      &-appstore {
        @include sprite($appstore-tw);
      }

      &-googleplay {
        @include sprite($googleplay-tw);
      }

      &-apk {
        @include sprite($apk-tw);
      }

      &-code {
        @include sprite($code-tw);
      }

      &-topup {
        @include sprite($topup-tw);
      }
    }

    .btn-download {
      font-family: chineseH;
      font-size: 60px;
    }
  }
}

.sg #block1 {
  .topup-sgmy {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    right: 209px;
    translate: 0 -50%;
    gap: 0;
    width: 154px;

    .link-topup-sg {
      @include sprite($topup-sg);
    }

    .link-topup-my {
      @include sprite($topup-my);
    }
  }
}
