@use "../../main/scss/mixin" as *;
@import "sprite.scss";

#details {
	.section__background {
		position: absolute;
		img {
			object-fit: none;
		}

		.bg-top {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
		}

		.bg-loop {
			background-image: url("../images/bg-loop.jpg");
			background-size: 100% 100%;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;

			@include mobile {
				background-image: url("../images/bg-loop-mb.jpg");
			}
		}

		.bg-bottom {
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 0;
		}
	}

	.section__content {
		.title {
			position: absolute;
			top: 146px;
			left: 50%;
			translate: -50% 0;
		}
		.news-bg {
			width: 1131px;
			height: fit-content;
			position: relative;
			right: 4px;
			margin-inline: auto;
			margin-bottom: 200px;
			margin-top: 287px;

			.top-menu {
				$accent-color: #e7dcc3;
				position: relative;
				top: 1px;
				right: 8px;
				width: 100%;
				padding-left: 40px;
				padding-right: 5px;
				height: 57px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				@include mobile {
					top: 14px;
					width: 750px;
				}

				.nav-container {
					flex-shrink: 0;
					display: flex;
					.btn {
						@include spriteBg;
						font-family: utm-talling;
						font-weight: 700;
						text-transform: uppercase;
						padding-bottom: 4px;
						font-size: 36px;
						flex-shrink: 0;
						min-width: 127px;
						padding-inline: 16px;
						height: 44px;
						color: $accent-color;
						&.active {
							background-image: url("../images/menu-selected.png");
							background-size: 100% 100%;
							color: black;
						}

						&:hover {
							color: #ed7f26;
							text-shadow: 0px 5px 2px #7d6e56, 0px 4px 2px #7d6e56, 0px 3px 2px #7d6e56,
								0px 2px 2px #7d6e56, 0px 1px 2px #7d6e56;
						}
					}
				}

				.search-form {
					@include sprite($search);
					display: flex;
					justify-content: space-between;
					align-items: center;
					input {
						height: 42px;
						width: 197px;
						padding: 8px;
						accent-color: $accent-color;
						color: $accent-color;
					}

					.btn-search {
						@include hoverDark();
						transition: filter 200ms ease-in-out;
						width: 64px;
						height: 50px;
						display: flex;
						justify-content: center;
						align-items: center;
						.search-icon {
							@include sprite($search-icon);
						}
					}
				}
			}

			.news-container {
				width: 1086px;
				height: fit-content;
				position: relative;
				left: 50.5%;
				// translate: -50% 0;
				transform: translateX(-50%);

				@include mobile {
					width: 700px;
					left: 38px;
					translate: 0 0;
					transform: translateX(0%);
				}

				.custom-news-select {
					z-index: 9999;
					@include sprite($select);
					display: flex;
					align-items: center;
					position: relative;
					padding-inline: 21px;
					margin-top: 36px;
					margin-left: 30px;
					font-size: 18px;
					font-family: roboto;
					font-weight: 500;
					select {
						display: none;
						pointer-events: none;
					}

					.arrow {
						@include sprite($arrow);
						position: absolute;
						top: 50%;
						right: 13px;
						translate: 0 -50%;
						rotate: 180deg;
						transition: rotate 200ms ease-in-out;
					}

					.select-item-list {
						position: absolute;
						width: 100%;
						top: 100%;
						left: 0;
						background-color: #d8d1c3;
						display: flex;
						flex-direction: column;
						max-height: 0;
						overflow: hidden;
						// transition: all 0.3s linear;
						.select-item {
							cursor: pointer;
							height: 42px;
							display: flex;
							align-items: center;
							padding-inline: 21px;
							&:hover {
								background-color: rgb(0, 0, 0, 0.3);
							}
						}
					}

					&.opened {
						.arrow {
							rotate: 0deg;
						}
						.select-item-list {
							max-height: 9999px;
						}
					}
				}

				.news-detail {
					width: 100%;
					margin-top: 20px;
					padding-inline: 30px;
					font-family: roboto;
					font-size: 20px;

					@include mobile {
						font-size: 24px;
					}

					table {
						margin-block: 16px;
						th,
						td {
							border: 1px solid;
							text-align: center;
							padding-block: 12px;
						}
						width: 100%;
						border-collapse: collapse;
						thead {
							background-color: #ac9878;
						}
					}

					ul {
						list-style-type: disc;
					}

					a {
						display: inline-block;
						color: black;
					}

					.news-title {
						font-family: utm-talling;
						font-weight: 700;
						font-size: 60px;
						margin-block: 27px;
					}

					.text-red {
						color: #ce2c00;
					}

					.date {
						font-family: roboto;
						font-weight: 400;
						font-size: 18px;
					}

					.separator {
						background-color: #d3c6ae;
						width: 100%;
						height: 2px;
						margin-block: 17px;
					}

					.image-container {
						width: 100%;
						margin-bottom: 51px;
						display: flex;
						flex-direction: column;
						align-items: center;
						img {
							max-width: 100%;
							display: block;
							margin-block: auto;
							object-fit: cover;
							margin-bottom: 10px;
						}

						.image-description {
							font-weight: 400;
							text-align: center;
						}
					}

					.news-paragraph {
						font-weight: 400;
					}
					img {
						max-width: 100%;
						width: auto;
						object-fit: cover;
						margin-bottom: 10px;
					}
				}
			}
		}
	}
}

.th #details {
	.section__content {
		.top-menu {
			.nav-container {
				.btn {
					font-family: sukhumvitset;
					font-weight: 700;
					font-size: 26px;
				}
			}
			.search-form {
				input {
					font-family: sukhumvitset;
					font-weight: 400;
				}
			}
		}
		.news-container {
			.custom-news-select {
				font-family: sukhumvitset;
			}
			.news-detail {
				font-family: sukhumvitset;

				.news-paragraph {
					font-weight: 400;
					font-size: 18px;
				}
			}
		}
	}
}

.tw #details {
	.section__content {
		.top-menu {
			.nav-container {
				.btn {
					font-family: chineseH;
					min-width: 100px;
					font-size: 36px;
				}
			}
			.search-form {
				input {
					font-family: chineseH;
					font-size: 24px;
				}
			}
		}
		.news-container {
			.custom-news-select {
				font-family: chineseH;
				font-size: 18px;
			}
			.news-detail {
				font-family: chineseH;
				.news-paragraph {
					font-weight: 400;
					font-size: 18px;
				}
			}
		}
	}
}

.vn #details {
	.section__content .news-bg .top-menu .nav-container {
		.btn {
			font-size: 38px;
		}
	}
}
