// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$age-mb-name: 'age-mb';
$age-mb-x: 334px;
$age-mb-y: 334px;
$age-mb-offset-x: -334px;
$age-mb-offset-y: -334px;
$age-mb-width: 143px;
$age-mb-height: 135px;
$age-mb-total-width: 955px;
$age-mb-total-height: 501px;
$age-mb-image: '../sprite.png';
$age-mb: (334px, 334px, -334px, -334px, 143px, 135px, 955px, 501px, '../sprite.png', 'age-mb', );
$age-name: 'age';
$age-x: 501px;
$age-y: 35px;
$age-offset-x: -501px;
$age-offset-y: -35px;
$age-width: 107px;
$age-height: 102px;
$age-total-width: 955px;
$age-total-height: 501px;
$age-image: '../sprite.png';
$age: (501px, 35px, -501px, -35px, 107px, 102px, 955px, 501px, '../sprite.png', 'age', );
$discord-mb-name: 'discord-mb';
$discord-mb-x: 596px;
$discord-mb-y: 137px;
$discord-mb-offset-x: -596px;
$discord-mb-offset-y: -137px;
$discord-mb-width: 94px;
$discord-mb-height: 94px;
$discord-mb-total-width: 955px;
$discord-mb-total-height: 501px;
$discord-mb-image: '../sprite.png';
$discord-mb: (596px, 137px, -596px, -137px, 94px, 94px, 955px, 501px, '../sprite.png', 'discord-mb', );
$discord-name: 'discord';
$discord-x: 0px;
$discord-y: 0px;
$discord-offset-x: 0px;
$discord-offset-y: 0px;
$discord-width: 167px;
$discord-height: 167px;
$discord-total-width: 955px;
$discord-total-height: 501px;
$discord-image: '../sprite.png';
$discord: (0px, 0px, 0px, 0px, 167px, 167px, 955px, 501px, '../sprite.png', 'discord', );
$facebook-mb-name: 'facebook-mb';
$facebook-mb-x: 690px;
$facebook-mb-y: 137px;
$facebook-mb-offset-x: -690px;
$facebook-mb-offset-y: -137px;
$facebook-mb-width: 94px;
$facebook-mb-height: 94px;
$facebook-mb-total-width: 955px;
$facebook-mb-total-height: 501px;
$facebook-mb-image: '../sprite.png';
$facebook-mb: (690px, 137px, -690px, -137px, 94px, 94px, 955px, 501px, '../sprite.png', 'facebook-mb', );
$facebook-name: 'facebook';
$facebook-x: 167px;
$facebook-y: 0px;
$facebook-offset-x: -167px;
$facebook-offset-y: 0px;
$facebook-width: 167px;
$facebook-height: 167px;
$facebook-total-width: 955px;
$facebook-total-height: 501px;
$facebook-image: '../sprite.png';
$facebook: (167px, 0px, -167px, 0px, 167px, 167px, 955px, 501px, '../sprite.png', 'facebook', );
$group-mb-name: 'group-mb';
$group-mb-x: 608px;
$group-mb-y: 35px;
$group-mb-offset-x: -608px;
$group-mb-offset-y: -35px;
$group-mb-width: 95px;
$group-mb-height: 94px;
$group-mb-total-width: 955px;
$group-mb-total-height: 501px;
$group-mb-image: '../sprite.png';
$group-mb: (608px, 35px, -608px, -35px, 95px, 94px, 955px, 501px, '../sprite.png', 'group-mb', );
$group-th-mb-name: 'group-th-mb';
$group-th-mb-x: 703px;
$group-th-mb-y: 35px;
$group-th-mb-offset-x: -703px;
$group-th-mb-offset-y: -35px;
$group-th-mb-width: 95px;
$group-th-mb-height: 94px;
$group-th-mb-total-width: 955px;
$group-th-mb-total-height: 501px;
$group-th-mb-image: '../sprite.png';
$group-th-mb: (703px, 35px, -703px, -35px, 95px, 94px, 955px, 501px, '../sprite.png', 'group-th-mb', );
$group-th-name: 'group-th';
$group-th-x: 0px;
$group-th-y: 167px;
$group-th-offset-x: 0px;
$group-th-offset-y: -167px;
$group-th-width: 167px;
$group-th-height: 167px;
$group-th-total-width: 955px;
$group-th-total-height: 501px;
$group-th-image: '../sprite.png';
$group-th: (0px, 167px, 0px, -167px, 167px, 167px, 955px, 501px, '../sprite.png', 'group-th', );
$group-tw-mb-name: 'group-tw-mb';
$group-tw-mb-x: 798px;
$group-tw-mb-y: 35px;
$group-tw-mb-offset-x: -798px;
$group-tw-mb-offset-y: -35px;
$group-tw-mb-width: 95px;
$group-tw-mb-height: 94px;
$group-tw-mb-total-width: 955px;
$group-tw-mb-total-height: 501px;
$group-tw-mb-image: '../sprite.png';
$group-tw-mb: (798px, 35px, -798px, -35px, 95px, 94px, 955px, 501px, '../sprite.png', 'group-tw-mb', );
$group-tw-name: 'group-tw';
$group-tw-x: 167px;
$group-tw-y: 167px;
$group-tw-offset-x: -167px;
$group-tw-offset-y: -167px;
$group-tw-width: 167px;
$group-tw-height: 167px;
$group-tw-total-width: 955px;
$group-tw-total-height: 501px;
$group-tw-image: '../sprite.png';
$group-tw: (167px, 167px, -167px, -167px, 167px, 167px, 955px, 501px, '../sprite.png', 'group-tw', );
$group-name: 'group';
$group-x: 334px;
$group-y: 0px;
$group-offset-x: -334px;
$group-offset-y: 0px;
$group-width: 167px;
$group-height: 167px;
$group-total-width: 955px;
$group-total-height: 501px;
$group-image: '../sprite.png';
$group: (334px, 0px, -334px, 0px, 167px, 167px, 955px, 501px, '../sprite.png', 'group', );
$instagram-mb-name: 'instagram-mb';
$instagram-mb-x: 784px;
$instagram-mb-y: 137px;
$instagram-mb-offset-x: -784px;
$instagram-mb-offset-y: -137px;
$instagram-mb-width: 94px;
$instagram-mb-height: 94px;
$instagram-mb-total-width: 955px;
$instagram-mb-total-height: 501px;
$instagram-mb-image: '../sprite.png';
$instagram-mb: (784px, 137px, -784px, -137px, 94px, 94px, 955px, 501px, '../sprite.png', 'instagram-mb', );
$instagram-name: 'instagram';
$instagram-x: 334px;
$instagram-y: 167px;
$instagram-offset-x: -334px;
$instagram-offset-y: -167px;
$instagram-width: 167px;
$instagram-height: 167px;
$instagram-total-width: 955px;
$instagram-total-height: 501px;
$instagram-image: '../sprite.png';
$instagram: (334px, 167px, -334px, -167px, 167px, 167px, 955px, 501px, '../sprite.png', 'instagram', );
$logo-name: 'logo';
$logo-x: 501px;
$logo-y: 0px;
$logo-offset-x: -501px;
$logo-offset-y: 0px;
$logo-width: 454px;
$logo-height: 35px;
$logo-total-width: 955px;
$logo-total-height: 501px;
$logo-image: '../sprite.png';
$logo: (501px, 0px, -501px, 0px, 454px, 35px, 955px, 501px, '../sprite.png', 'logo', );
$tiktok-mb-name: 'tiktok-mb';
$tiktok-mb-x: 501px;
$tiktok-mb-y: 231px;
$tiktok-mb-offset-x: -501px;
$tiktok-mb-offset-y: -231px;
$tiktok-mb-width: 94px;
$tiktok-mb-height: 94px;
$tiktok-mb-total-width: 955px;
$tiktok-mb-total-height: 501px;
$tiktok-mb-image: '../sprite.png';
$tiktok-mb: (501px, 231px, -501px, -231px, 94px, 94px, 955px, 501px, '../sprite.png', 'tiktok-mb', );
$tiktok-name: 'tiktok';
$tiktok-x: 0px;
$tiktok-y: 334px;
$tiktok-offset-x: 0px;
$tiktok-offset-y: -334px;
$tiktok-width: 167px;
$tiktok-height: 167px;
$tiktok-total-width: 955px;
$tiktok-total-height: 501px;
$tiktok-image: '../sprite.png';
$tiktok: (0px, 334px, 0px, -334px, 167px, 167px, 955px, 501px, '../sprite.png', 'tiktok', );
$youtube-mb-name: 'youtube-mb';
$youtube-mb-x: 501px;
$youtube-mb-y: 137px;
$youtube-mb-offset-x: -501px;
$youtube-mb-offset-y: -137px;
$youtube-mb-width: 95px;
$youtube-mb-height: 94px;
$youtube-mb-total-width: 955px;
$youtube-mb-total-height: 501px;
$youtube-mb-image: '../sprite.png';
$youtube-mb: (501px, 137px, -501px, -137px, 95px, 94px, 955px, 501px, '../sprite.png', 'youtube-mb', );
$youtube-name: 'youtube';
$youtube-x: 167px;
$youtube-y: 334px;
$youtube-offset-x: -167px;
$youtube-offset-y: -334px;
$youtube-width: 167px;
$youtube-height: 167px;
$youtube-total-width: 955px;
$youtube-total-height: 501px;
$youtube-image: '../sprite.png';
$youtube: (167px, 334px, -167px, -334px, 167px, 167px, 955px, 501px, '../sprite.png', 'youtube', );
$spritesheet-width: 955px;
$spritesheet-height: 501px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($age-mb, $age, $discord-mb, $discord, $facebook-mb, $facebook, $group-mb, $group-th-mb, $group-th, $group-tw-mb, $group-tw, $group, $instagram-mb, $instagram, $logo, $tiktok-mb, $tiktok, $youtube-mb, $youtube, );
$spritesheet: (955px, 501px, '../sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
