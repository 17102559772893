// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$language-name: 'language';
$language-x: 0px;
$language-y: 0px;
$language-offset-x: 0px;
$language-offset-y: 0px;
$language-width: 82px;
$language-height: 82px;
$language-total-width: 152px;
$language-total-height: 82px;
$language-image: '../sprite.png';
$language: (0px, 0px, 0px, 0px, 82px, 82px, 152px, 82px, '../sprite.png', 'language', );
$menu-name: 'menu';
$menu-x: 82px;
$menu-y: 0px;
$menu-offset-x: -82px;
$menu-offset-y: 0px;
$menu-width: 70px;
$menu-height: 44px;
$menu-total-width: 152px;
$menu-total-height: 82px;
$menu-image: '../sprite.png';
$menu: (82px, 0px, -82px, 0px, 70px, 44px, 152px, 82px, '../sprite.png', 'menu', );
$spritesheet-width: 152px;
$spritesheet-height: 82px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($language, $menu, );
$spritesheet: (152px, 82px, '../sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
