// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$arrow-name: 'arrow';
$arrow-x: 356px;
$arrow-y: 44px;
$arrow-offset-x: -356px;
$arrow-offset-y: -44px;
$arrow-width: 24px;
$arrow-height: 14px;
$arrow-total-width: 380px;
$arrow-total-height: 180px;
$arrow-image: '../sprite.png';
$arrow: (356px, 44px, -356px, -44px, 24px, 14px, 380px, 180px, '../sprite.png', 'arrow', );
$btn-yellow-mb-name: 'btn-yellow-mb';
$btn-yellow-mb-x: 173px;
$btn-yellow-mb-y: 93px;
$btn-yellow-mb-offset-x: -173px;
$btn-yellow-mb-offset-y: -93px;
$btn-yellow-mb-width: 132px;
$btn-yellow-mb-height: 42px;
$btn-yellow-mb-total-width: 380px;
$btn-yellow-mb-total-height: 180px;
$btn-yellow-mb-image: '../sprite.png';
$btn-yellow-mb: (173px, 93px, -173px, -93px, 132px, 42px, 380px, 180px, '../sprite.png', 'btn-yellow-mb', );
$btn-yellow-name: 'btn-yellow';
$btn-yellow-x: 0px;
$btn-yellow-y: 93px;
$btn-yellow-offset-x: 0px;
$btn-yellow-offset-y: -93px;
$btn-yellow-width: 173px;
$btn-yellow-height: 55px;
$btn-yellow-total-width: 380px;
$btn-yellow-total-height: 180px;
$btn-yellow-image: '../sprite.png';
$btn-yellow: (0px, 93px, 0px, -93px, 173px, 55px, 380px, 180px, '../sprite.png', 'btn-yellow', );
$date-container-name: 'date-container';
$date-container-x: 258px;
$date-container-y: 44px;
$date-container-offset-x: -258px;
$date-container-offset-y: -44px;
$date-container-width: 98px;
$date-container-height: 25px;
$date-container-total-width: 380px;
$date-container-total-height: 180px;
$date-container-image: '../sprite.png';
$date-container: (258px, 44px, -258px, -44px, 98px, 25px, 380px, 180px, '../sprite.png', 'date-container', );
$hot-name: 'hot';
$hot-x: 305px;
$hot-y: 93px;
$hot-offset-x: -305px;
$hot-offset-y: -93px;
$hot-width: 45px;
$hot-height: 35px;
$hot-total-width: 380px;
$hot-total-height: 180px;
$hot-image: '../sprite.png';
$hot: (305px, 93px, -305px, -93px, 45px, 35px, 380px, 180px, '../sprite.png', 'hot', );
$pagination-selected-name: 'pagination-selected';
$pagination-selected-x: 0px;
$pagination-selected-y: 148px;
$pagination-selected-offset-x: 0px;
$pagination-selected-offset-y: -148px;
$pagination-selected-width: 39px;
$pagination-selected-height: 32px;
$pagination-selected-total-width: 380px;
$pagination-selected-total-height: 180px;
$pagination-selected-image: '../sprite.png';
$pagination-selected: (0px, 148px, 0px, -148px, 39px, 32px, 380px, 180px, '../sprite.png', 'pagination-selected', );
$pagination-name: 'pagination';
$pagination-x: 39px;
$pagination-y: 148px;
$pagination-offset-x: -39px;
$pagination-offset-y: -148px;
$pagination-width: 39px;
$pagination-height: 32px;
$pagination-total-width: 380px;
$pagination-total-height: 180px;
$pagination-image: '../sprite.png';
$pagination: (39px, 148px, -39px, -148px, 39px, 32px, 380px, 180px, '../sprite.png', 'pagination', );
$search-icon-name: 'search-icon';
$search-icon-x: 78px;
$search-icon-y: 148px;
$search-icon-offset-x: -78px;
$search-icon-offset-y: -148px;
$search-icon-width: 31px;
$search-icon-height: 31px;
$search-icon-total-width: 380px;
$search-icon-total-height: 180px;
$search-icon-image: '../sprite.png';
$search-icon: (78px, 148px, -78px, -148px, 31px, 31px, 380px, 180px, '../sprite.png', 'search-icon', );
$search-name: 'search';
$search-x: 0px;
$search-y: 44px;
$search-offset-x: 0px;
$search-offset-y: -44px;
$search-width: 258px;
$search-height: 49px;
$search-total-width: 380px;
$search-total-height: 180px;
$search-image: '../sprite.png';
$search: (0px, 44px, 0px, -44px, 258px, 49px, 380px, 180px, '../sprite.png', 'search', );
$select-name: 'select';
$select-x: 0px;
$select-y: 0px;
$select-offset-x: 0px;
$select-offset-y: 0px;
$select-width: 380px;
$select-height: 44px;
$select-total-width: 380px;
$select-total-height: 180px;
$select-image: '../sprite.png';
$select: (0px, 0px, 0px, 0px, 380px, 44px, 380px, 180px, '../sprite.png', 'select', );
$spritesheet-width: 380px;
$spritesheet-height: 180px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($arrow, $btn-yellow-mb, $btn-yellow, $date-container, $hot, $pagination-selected, $pagination, $search-icon, $search, $select, );
$spritesheet: (380px, 180px, '../sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
