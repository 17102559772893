// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$arrow-name: 'arrow';
$arrow-x: 289px;
$arrow-y: 44px;
$arrow-offset-x: -289px;
$arrow-offset-y: -44px;
$arrow-width: 24px;
$arrow-height: 14px;
$arrow-total-width: 380px;
$arrow-total-height: 148px;
$arrow-image: '../sprite.png';
$arrow: (289px, 44px, -289px, -44px, 24px, 14px, 380px, 148px, '../sprite.png', 'arrow', );
$btn-yellow-name: 'btn-yellow';
$btn-yellow-x: 0px;
$btn-yellow-y: 93px;
$btn-yellow-offset-x: 0px;
$btn-yellow-offset-y: -93px;
$btn-yellow-width: 173px;
$btn-yellow-height: 55px;
$btn-yellow-total-width: 380px;
$btn-yellow-total-height: 148px;
$btn-yellow-image: '../sprite.png';
$btn-yellow: (0px, 93px, 0px, -93px, 173px, 55px, 380px, 148px, '../sprite.png', 'btn-yellow', );
$menu-selected-name: 'menu-selected';
$menu-selected-x: 173px;
$menu-selected-y: 93px;
$menu-selected-offset-x: -173px;
$menu-selected-offset-y: -93px;
$menu-selected-width: 127px;
$menu-selected-height: 44px;
$menu-selected-total-width: 380px;
$menu-selected-total-height: 148px;
$menu-selected-image: '../sprite.png';
$menu-selected: (173px, 93px, -173px, -93px, 127px, 44px, 380px, 148px, '../sprite.png', 'menu-selected', );
$search-icon-name: 'search-icon';
$search-icon-x: 258px;
$search-icon-y: 44px;
$search-icon-offset-x: -258px;
$search-icon-offset-y: -44px;
$search-icon-width: 31px;
$search-icon-height: 31px;
$search-icon-total-width: 380px;
$search-icon-total-height: 148px;
$search-icon-image: '../sprite.png';
$search-icon: (258px, 44px, -258px, -44px, 31px, 31px, 380px, 148px, '../sprite.png', 'search-icon', );
$search-name: 'search';
$search-x: 0px;
$search-y: 44px;
$search-offset-x: 0px;
$search-offset-y: -44px;
$search-width: 258px;
$search-height: 49px;
$search-total-width: 380px;
$search-total-height: 148px;
$search-image: '../sprite.png';
$search: (0px, 44px, 0px, -44px, 258px, 49px, 380px, 148px, '../sprite.png', 'search', );
$select-name: 'select';
$select-x: 0px;
$select-y: 0px;
$select-offset-x: 0px;
$select-offset-y: 0px;
$select-width: 380px;
$select-height: 44px;
$select-total-width: 380px;
$select-total-height: 148px;
$select-image: '../sprite.png';
$select: (0px, 0px, 0px, 0px, 380px, 44px, 380px, 148px, '../sprite.png', 'select', );
$spritesheet-width: 380px;
$spritesheet-height: 148px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($arrow, $btn-yellow, $menu-selected, $search-icon, $search, $select, );
$spritesheet: (380px, 148px, '../sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
