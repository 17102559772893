@import "../../main/scss/mixin";
@import "sprite.scss";

#block4 {
  .kukik {
    right: 557px;
    @include mobile {
      transform: scale(1.32);
      transform-origin: right;
    }
  }

  .section__background {
    img {
      width: 100%;
      height: 100%;
    }

    // .diag-top {
    // 	@include sprite($diag-top);
    // 	@include mobile {
    // 		@include sprite($diag-top-mb);
    // 	}
    // }
    // .diag-bot {
    // 	@include sprite($diag-bot);f
    // 	@include mobile {
    // 		@include sprite($diag-bot-mb);
    // 	}
    // }
  }

  .section__content {
    .title {
      position: absolute;
      top: 165px;
      left: 50%;
      translate: -50% 0;
    }

    .top-nav-container {
      display: flex;
      gap: 8px;
      justify-content: center;
      position: relative;
      top: 267px;
      z-index: 100;

      button {
        @include sprite($btn-menu);
        @include hoverLight();
        flex-shrink: 0;
        transition: filter 200ms ease-in-out;
        color: #eb7d24;
        font-family: utm-talling;
        font-weight: 700;
        font-size: 40px;
        padding-bottom: 4px;

        &.active {
          @include sprite($btn-menu-selected);
          color: #fff;
        }

        @include mobile {
          @include sprite($btn-menu-mb);
          font-size: 35.83px;
          &.active {
            @include sprite($btn-menu-selected-mb);
          }
        }
      }
    }

    .block4-swiper-container {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      translate: -50% 0;
      z-index: 10;
      width: var(--width-desktop);
      height: 712px;
      @include mobile {
        bottom: 101px;
        width: var(--width-mobile);
      }

      .swiper-container {
        width: 1505px;
        height: 100%;
        position: absolute;
        left: 50%;
        translate: -50%;
        transform-origin: center center;
        transition: all 200ms ease-in-out;
        visibility: hidden;
        pointer-events: none;
        opacity: 0;
        scale: 0;

        @include mobile {
          width: 100%;
        }

        .swiper-slide {
          bottom: 30px;
        }

        &.active {
          visibility: visible;
          pointer-events: all;
          opacity: 1;
          scale: 1;
        }

        .swiper-button {
          $y-size: 187px;
          z-index: 999;

          &::after {
            display: none;
          }

          &-prev {
            @include sprite($swiper-prev);
            right: unset;
            left: -109px;

            @include mobile {
              @include sprite($swiper-prev-mb);
              left: 0;
              top: $y-size;
              bottom: unset;
            }
          }

          &-next {
            @include sprite($swiper-next);
            left: unset;
            right: -10px;

            @include mobile {
              @include sprite($swiper-next-mb);
              right: 0;
              top: $y-size;
              bottom: unset;
            }
          }
        }
      }

      .swiper {
        position: absolute;
        left: 50%;
        translate: -50% 0;
      }

      .nav-button {
        flex-shrink: 0;
        font-family: utm-talling;
        font-weight: 700;
        font-size: 30px;
        text-transform: uppercase;
        padding-bottom: 8px;
        @include sprite($btn-character-mb);
        color: #000;
        @include mobile {
          @include sprite($btn-character-mb);
          display: inline-flex;
          margin-bottom: 15px;
        }
        @include spriteBg;
        @include hoverLight();
        transition: filter 200ms ease-in-out;
        border-radius: 0;
        background-color: transparent;
        opacity: 1;

        &.active {
          @include sprite($btn-character-selected-mb);
          color: #000;
          @include mobile {
            @include sprite($btn-character-selected-mb);
          }
        }
      }
      .event-thumb_list{
        width: 1400px;
        position: absolute;
        bottom: 35px;
      }
      #character-swiper {
        width: 1473px;
        height: 100%;
        overflow: hidden;
        #character-pagination{
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          position: absolute;
          bottom: 20px;
          left: 50%;
          translate: -50% 0;
          z-index: 999;
          .swiper-slide{
            height: 69px;
            align-items: center;
            justify-content: center;
          }
        }
        @include mobile {
          width: 100%;
          overflow: visible;
        }

        .swiper-slide {
          display: flex;
          align-items: flex-end;
          position: relative;
          z-index: 10;

          .video-container {
            width: fit-content;
            height: fit-content;
            position: absolute;
            left: 0;
            bottom: 40px;
            z-index: 11;

            @include mobile {
              left: unset;
              right: 16px;
              bottom: 75px;
            }

            .video-bg {
              background-image: url("../images/video-bg.png");
              background-size: 100% 100%;
              width: 606px;
              height: 585px;
              position: relative;
              z-index: 12;

              @include mobile {
                width: 462px;
                height: 462px;
              }

              .btn-play {
                @include sprite($btn-play);
                @include spriteImg;
                @include hoverLight();
                @include hoverBig();
                transition: filter 200ms ease-in-out, scale 150ms ease-in-out;
                position: absolute;
                top: 45%;
                left: 47%;
                translate: -50% -50%;
              }
            }

            img {
              position: absolute;
              z-index: 11;
              border-radius: 50%;
              top: 24px;
              left: 46%;
              translate: -50% 0;
              object-fit: contain;

              @include mobile {
                width: 394px;
                height: 384px;
              }
            }
          }

          .character-img {
            position: absolute;
            right: 597px;
            bottom: 0;
            z-index: 13;
            width: auto;
            height: 672px;
            max-width: 525px;
            object-fit: contain;

            @include mobile {
              right: unset;
              bottom: 30px;
              left: 0;
              width: auto;
              height: 638px;
              max-width: 470px;
            }
          }

          .character-description {
            width: 406px;
            height: 470px;
            position: absolute;
            bottom: 122px;
            right: 156px;
            z-index: 9999;

            @include mobile {
              width: 366px;
              height: 236px;
              bottom: unset;
              top: -83px;
              right: 53px;
            }

            .lightning {
              @include sprite($lightning);
              position: absolute;
              top: -31px;
              left: -22px;
              translate: -100% 0;

              @include mobile {
                top: 0;
              }
            }

            .content {
              display: flex;
              flex-direction: column;
              gap: 25px;
              padding-left: 4px;
              margin-top: -50px;

              @include mobile {
                height: 218px;
                padding-top: 64px;
                margin-top: 0px;
              }

              .character-name {
                @include textBorder(3px, #000);
                color: #dd7826;
                font-family: utm-talling;
                font-weight: 700;
                font-size: 67px;
                line-height: 40px;
                text-transform: uppercase;
                @include mobile {
                  font-size: 57px;
                }
              }

              .character-text {
                display: flex;
                flex-direction: column;
                gap: 25px;
                max-height: 450px;
                overflow: auto;
                @include mobile {
                  height: 141px;
                  overflow: auto;
                }

                p {
                  font-family: roboto;
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 24px;
                  color: #e4d6b6;
                }
              }
            }
          }
        }

        .swiper-pagination {
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0px;
          row-gap: 14px;
          position: absolute;
          bottom: 15px;
          left: 50%;
          translate: -50% 0;

          z-index: 100;
          flex-wrap: wrap;
          @include mobile {
            display: block;
            bottom: -70px;
          }


        }
      }

      #weapon-swiper {
        width: 1505px;
        height: 100%;
        overflow: hidden;

        @include mobile {
          width: 100%;
          overflow: visible;
        }

        .swiper-slide {
          position: relative;
          z-index: 10;

          .weapon-img {
            position: absolute;
            left: 378px;
            bottom: 245px;
            width: 482px;
            object-fit: contain;

            @include mobile {
              left: 66px;
              bottom: 207px;
              width: 485px;
            }
          }

          .weapon-content {
            @include sprite($weapon-detail-bg);
            position: absolute;
            bottom: 257px;
            left: 962px;
            z-index: 9999;
            font-family: roboto;
            font-weight: 500;
            font-size: 18px;

            @include mobile {
              @include sprite($weapon-detail-bg-mb);
              bottom: unset;
              left: unset;
              top: -20px;
              right: 65px;
            }

            .weapon-name {
              @include textBorder(3px, #000);
              color: #dd7826;
              position: absolute;
              top: -68px;
              left: 0;
              font-family: utm-talling;
              font-weight: 700;
              font-size: 67px;
              line-height: 40px;
              @include mobile {
                font-size: 57px;
              }
            }

            .table-title {
              color: #e4d6b6;
              position: absolute;
              top: 15px;
              left: 15px;

              @include mobile {
                top: 8px;
                left: 8px;
              }
            }

            .row-container {
              position: absolute;
              bottom: 8px;
              left: 49.55%;
              translate: -50% 0;
              display: flex;
              flex-direction: column;
              gap: 1px;

              @include mobile {
                left: 50%;
              }

              .row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-left: 18px;
                padding-right: 27px;

                &:nth-child(even) {
                  @include sprite($weapon-row-dark);
                  @include mobile {
                    @include sprite($weapon-row-dark-mb);
                  }
                }

                &:nth-child(odd) {
                  @include sprite($weapon-row-light);
                  @include mobile {
                    @include sprite($weapon-row-light-mb);
                  }
                }

                .left {
                  display: grid;
                  grid-template-columns: 40px auto;
                  gap: 14px;

                  .icon {
                    align-self: flex-end;
                    display: block;

                    &-gun {
                      @include sprite($gun-icon);
                    }

                    &-heart {
                      @include sprite($heart-icon);
                    }

                    &-bullet {
                      @include sprite($bullet-icon);
                    }

                    &-target {
                      @include sprite($target-icon);
                    }
                  }

                  .text {
                    color: #e4d6b6;
                  }
                }

                .right {
                  .value {
                    color: #e4d6b6;
                    font-weight: 400;
                  }
                }
              }
            }

            .lightning {
              @include sprite($lightning);
              position: absolute;
              top: -86px;
              left: -22px;
              translate: -100% 0;
            }
          }
        }

        #weapon-pagination {
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          position: absolute;
          bottom: 20px;
          left: 50%;
          translate: -50% 0;
          z-index: 999;

          @include mobile {
            width: calc(100% - 2 * 16px);
          }

          .nav-button {
            @include sprite($btn-pagination);

            @include mobile {
              @include sprite($btn-pagination-mb);
            }

            @include spriteImg;
            @include hoverLight();
            cursor: pointer;
            transition: filter 200ms ease-in-out;
            border-radius: 0;
            background-color: transparent;
            opacity: 1;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            .gun-inner {
              transition: scale 250ms ease-in-out;
              max-width: 288px;
              max-height: 190px;
              object-fit: contain;

              @include mobile {
                max-width: 229px;
                max-height: 151px;
              }
            }

            &.active {
              @include sprite($btn-pagination-selected);

              @include mobile {
                @include sprite($btn-pagination-selected-mb);
              }
            }

            &:hover,
            &.active {
              .gun-inner {
                scale: 1.2;
                transform-origin: bottom right;
              }
            }
          }
        }
      }

      #vehicle-swiper {
        width: 1505px;
        height: 672px;
        overflow: hidden;

        @include mobile {
          width: 100%;
          overflow: visible;
        }

        .swiper-slide {
          position: relative;
          z-index: 10;

          .vehicle-img {
            position: absolute;
            left: 320px;
            bottom: 245px;
            height: 400px;
            object-fit: contain;

            @include mobile {
              left: 21px;
              bottom: 184px;
              width: 374px;
            }
          }

          .vehicle-description {
            width: 400px;
            height: 318px;
            position: absolute;
            right: 152px;
            bottom: 240px;
            z-index: 9999;

            @include mobile {
              width: 380px;
              height: 267px;
              bottom: unset;
              top: -78px;
              right: 47px;
            }

            .lightning {
              @include sprite($lightning);
              position: absolute;
              top: -31px;
              left: -22px;
              translate: -100% 0;

              @include mobile {
                top: 0;
              }
            }

            .content {
              display: flex;
              flex-direction: column;
              gap: 25px;

              .vehicle-name {
                @include textBorder(3px, #000);
                font-family: utm-talling;
                font-weight: 700;
                font-size: 72px;
                line-height: 40px;
                color: #dd7826;
              }

              .vehicle-text {
                display: flex;
                flex-direction: column;
                gap: 25px;

                @include mobile {
                  height: 230px;
                  overflow: auto;
                }

                p {
                  font-family: roboto;
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 24px;
                  color: #e4d6b6;
                }
              }
            }
          }
        }

        #vehicle-pagination {
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: 20px;
          width: 100%;
          left: 50%;
          translate: -50% 0;
          z-index: 999;

          @include mobile {
            width: calc(100% - 2 * 16px);
          }

          .nav-button {
            @include sprite($btn-pagination);
            @include mobile {
              @include sprite($btn-pagination-mb);
            }
            @include spriteImg;
            @include hoverLight();
            transition: filter 200ms ease-in-out;
            border-radius: 0;
            background-color: transparent;
            opacity: 1;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            .vehicle-inner {
              transition: scale 250ms ease-in-out;
              max-width: 288px;
              max-height: 190px;
              object-fit: contain;

              @include mobile {
                max-width: 229px;
                max-height: 151px;
              }
            }

            &.active {
              @include sprite($btn-pagination-selected);
              @include mobile {
                @include sprite($btn-pagination-selected-mb);
              }
            }

            &:hover,
            &.active {
              .vehicle-inner {
                scale: 1.3;
                transform-origin: bottom center;
              }
            }
          }
        }
      }
    }
  }
}

.vn #block4 {
  .title {
    @include mobile {
      top: 145px;
    }
  }

  .top-nav-container {
  }

  .block4-swiper-container {
    #character-swiper {
      .swiper-pagination {
        .nav-button {
        }
      }
    }
  }
}

.th #block4 {
  .title {
    top: 125px;
  }

  .top-nav-container {
    button {
      font-family: sukhumvitset;
      font-weight: 700;
      font-size: 36px;
      padding-bottom: 6px;
    }
  }

  .block4-swiper-container {

    #character-swiper {
      .swiper-slide .character-description .content .character-text p {
        font-family: sukhumvitset;
        font-weight: 400;
        font-size: 18px;
      }
    }

    #weapon-swiper {
      .swiper-slide {
        .weapon-content {
          font-family: sukhumvitset;
          font-weight: 500;
        }
      }
    }

    #vehicle-swiper {
      .swiper-slide {
        .vehicle-description {
          .content {
            .vehicle-text p {
              font-family: sukhumvitset;
              font-weight: 400;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

.tw #block4 {
  .title {
    top: 115px;
  }

  .top-nav-container {
    button {
      font-weight: 400;
      font-size: 36px;
      padding-bottom: 6px;
    }
  }

  .block4-swiper-container {
    #character-swiper {
      .swiper-slide .character-description .content .character-text p {
        font-family: chineseH;
        font-size: 18px;
      }

      .swiper-pagination .nav-button {
        font-family: chineseH;
        font-size: 35px;
        padding-bottom: 4px;
      }
    }

    #weapon-swiper {
      .swiper-slide {
        .weapon-content {
          font-family: chineseH;
        }
      }
    }

    #vehicle-swiper {
      .swiper-slide {
        .vehicle-description {
          .content {
            .vehicle-text p {
              font-family: chineseH;
            }
          }
        }
      }
    }
  }
}
