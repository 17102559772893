@use "../../main/scss/mixin" as *;
@import "./sprite.scss";

#float_bottom {
	.float__content {
		bottom: 0;
		left: 0;
		width: var(--width-mobile);
		height: 113px;
		position: relative;
		.btn-menu {
			@include sprite($menu);
			@include hoverLight();
			position: absolute;
			bottom: 30px;
			left: 36px;
		}

		.navigation {
			background: url("../images/menu-dropdown.png") center top no-repeat #151313;
			width: 768px;
			//height: 453px;
			position: absolute;
			top: 4px;
			translate: 0 -100%;
			scale: 1 0;
			opacity: 0;
			transform-origin: bottom center;
			transition: all 0.2s ease-in-out;
			text-transform: uppercase;
			&.active {
				scale: 1 1;
				opacity: 1;
			}

			.list-container {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.list-item {
					width: 100%;
					flex-shrink: 0;
					height: 70px;
					position: relative;

					&::after {
						content: "";
						width: 90%;
						height: 2px;
						background-color: #211d1d;
						position: absolute;
						left: 50%;
						bottom: 0;
						translate: -50% 0;
					}
					a {
						font-family: utm-talling;
						font-weight: 500;
						font-size: 36px;
						color: #e8dabe;
						display: block;
						width: 100%;
						height: 100%;

						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}
		}

		.language {
			@include sprite($language);
			@include hoverLight();
			transition: filter 200ms ease-in-out;
			position: absolute;
			bottom: 12px;
			right: 16px;
			display: flex;
			align-items: center;
			z-index: 200;
			font-family: utm-talling;
			@include textBorder(3px, #000);
			input {
				width: 100%;
				height: 100%;
				cursor: pointer;
			}

			.floattop__item--language {
				display: flex;
				align-items: center;
				justify-content: center;
				background-repeat: no-repeat;
				&:after {
					width: 16px;
					height: 8px;
					content: "";
					display: block;
					position: absolute;
					top: 50%;
					right: 5px;
					transform: translateY(-50%);
				}
			}

			&__list {
				background-image: url("../images/language-dropdown.png");
				width: 261px;
				height: 450px;
				position: absolute;
				top: -17px;
				right: -17px;
				translate: 0 -100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				max-height: 0;
				overflow: hidden;
				transition: all 0.4s;

				.dropdown-content {
					width: 100%;
					position: relative;
					top: 50%;
					translate: 0 -50%;
				}
			}

			&__item {
				height: 64px;
				text-align: center;
				padding: 6px 8px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				font-size: 36px;

				&::after {
					content: "";
					width: 90%;
					height: 2px;
					background-color: #282222;
					position: absolute;
					bottom: 0;
					left: 50%;
					translate: -50% 0;
				}

				&:last-child::after {
					display: none;
				}

				a {
					display: flex !important;
					align-items: center;
					justify-content: center;
					color: #ffefd6;
					width: 100%;
					height: 100%;
				}
				&:hover,
				&.active {
					cursor: pointer;
					background: rgb(55, 46, 42);
				}
			}

			input:checked + label + .language__list {
				max-height: 999px;
				padding: 1px;
			}
		}
	}
}

.th #float_bottom {
	.float__content {
		.navigation {
			.list-item {
				a {
					font-family: sukhumvitset;
					font-size: 36px;
				}
				.utm {
					font-family: utm-talling !important;
					font-size: 36px !important;
				}
			}
		}
	}
}

.tw #float_bottom {
	.float__content {
		.navigation {
			.list-item {
				a {
					font-family: chineseH;
					font-size: 48px;
				}
				.utm {
					font-family: utm-talling !important;
					font-size: 36px !important;
				}
			}
		}
	}
}
