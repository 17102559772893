@import "../../main/scss/mixin";
@import "sprite.scss";

#float_right {
	.float__content {
		background-image: url("../images/bg.png");
		right: 20px;
		top: 279px;
		width: 170px;
		height: 624px;
		position: absolute;

		.qr-code {
			position: absolute;
			left: 50%;
			top: 135px;
			translate: -50% 0;
		}

		.link {
			@include hoverLight();
			transition: filter 200ms ease-in-out;
		}

		.link-container-1 {
			position: absolute;
			display: flex;
			flex-direction: column;
			top: 282px;
			left: 50%;
			translate: -50% 0;

			.link {
				@include spriteImg;
				&-ios {
					@include sprite($ios-en);
				}

				&-apk {
					@include sprite($apk-en);
				}

				&-android {
					@include sprite($android-en);
				}
			}
		}

		.link-container-2 {
			position: absolute;
			display: flex;
			flex-direction: column;
			top: 446px;
			left: 50%;
			translate: -50% 0;

			.link {
				@include spriteImg;
				&-topup {
					@include sprite($topup-en);
				}

				&-guide {
					@include sprite($guide-en);
				}
			}
		}

		.link-container-3 {
			width: 145px;
			height: 41px;
			position: absolute;
			display: flex;
			justify-content: space-between;
			top: 564px;
			left: 50%;
			translate: -50% 0;

			.link {
				@include spriteImg;
				flex-shrink: 0;
				&-facebook {
					@include sprite($facebook);
				}

				&-group {
					@include sprite($group);
				}

				&-tiktok {
					@include sprite($tiktok);
				}
			}
		}
	}
}

.vn #float_right {
	.float__content {
		.link-container-2 {
			.link {
				&-topup {
					@include sprite($topup-vi);
				}

				&-guide {
					@include sprite($guide-vi);
				}
			}
		}
	}
}

.id #float_right {
	.float__content {
		.link-container-1 {
			.link {
				&-ios {
					@include sprite($ios-id);
				}

				&-apk {
					@include sprite($apk-id);
				}

				&-android {
					@include sprite($android-id);
				}
			}
		}

		.link-container-2 {
			.link {
				&-topup {
					@include sprite($topup-id);
				}

				&-guide {
					@include sprite($guide-id);
				}
			}
		}
	}
}

.th #float_right {
	.float__content {
		.link-container-1 {
			.link {
				&-ios {
					@include sprite($ios-th);
				}

				&-apk {
					@include sprite($apk-th);
				}

				&-android {
					@include sprite($android-th);
				}
			}
		}

		.link-container-2 {
			.link {
				&-topup {
					@include sprite($topup-th);
				}

				&-guide {
					@include sprite($guide-th);
				}
			}
		}
	}
}

.tw #float_right {
	.float__content {
		.link-container-1 {
			.link {
				&-ios {
					@include sprite($ios-tw);
				}

				&-apk {
					@include sprite($apk-tw);
				}

				&-android {
					@include sprite($android-tw);
				}
			}
		}

		.link-container-2 {
			.link {
				&-topup {
					@include sprite($topup-tw);
				}

				&-guide {
					@include sprite($guide-tw);
				}
			}
		}
	}
}