// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$btn-character-mb-name: 'btn-character-mb';
$btn-character-mb-x: 779px;
$btn-character-mb-y: 466px;
$btn-character-mb-offset-x: -779px;
$btn-character-mb-offset-y: -466px;
$btn-character-mb-width: 182px;
$btn-character-mb-height: 52px;
$btn-character-mb-total-width: 1022px;
$btn-character-mb-total-height: 778px;
$btn-character-mb-image: '../sprite.png';
$btn-character-mb: (779px, 466px, -779px, -466px, 182px, 52px, 1022px, 778px, '../sprite.png', 'btn-character-mb', );
$btn-character-selected-mb-name: 'btn-character-selected-mb';
$btn-character-selected-mb-x: 779px;
$btn-character-selected-mb-y: 266px;
$btn-character-selected-mb-offset-x: -779px;
$btn-character-selected-mb-offset-y: -266px;
$btn-character-selected-mb-width: 189px;
$btn-character-selected-mb-height: 69px;
$btn-character-selected-mb-total-width: 1022px;
$btn-character-selected-mb-total-height: 778px;
$btn-character-selected-mb-image: '../sprite.png';
$btn-character-selected-mb: (779px, 266px, -779px, -266px, 189px, 69px, 1022px, 778px, '../sprite.png', 'btn-character-selected-mb', );
$btn-character-selected-name: 'btn-character-selected';
$btn-character-selected-x: 457px;
$btn-character-selected-y: 429px;
$btn-character-selected-offset-x: -457px;
$btn-character-selected-offset-y: -429px;
$btn-character-selected-width: 280px;
$btn-character-selected-height: 105px;
$btn-character-selected-total-width: 1022px;
$btn-character-selected-total-height: 778px;
$btn-character-selected-image: '../sprite.png';
$btn-character-selected: (457px, 429px, -457px, -429px, 280px, 105px, 1022px, 778px, '../sprite.png', 'btn-character-selected', );
$btn-character-name: 'btn-character';
$btn-character-x: 0px;
$btn-character-y: 580px;
$btn-character-offset-x: 0px;
$btn-character-offset-y: -580px;
$btn-character-width: 266px;
$btn-character-height: 79px;
$btn-character-total-width: 1022px;
$btn-character-total-height: 778px;
$btn-character-image: '../sprite.png';
$btn-character: (0px, 580px, 0px, -580px, 266px, 79px, 1022px, 778px, '../sprite.png', 'btn-character', );
$btn-menu-mb-name: 'btn-menu-mb';
$btn-menu-mb-x: 779px;
$btn-menu-mb-y: 0px;
$btn-menu-mb-offset-x: -779px;
$btn-menu-mb-offset-y: 0px;
$btn-menu-mb-width: 243px;
$btn-menu-mb-height: 57px;
$btn-menu-mb-total-width: 1022px;
$btn-menu-mb-total-height: 778px;
$btn-menu-mb-image: '../sprite.png';
$btn-menu-mb: (779px, 0px, -779px, 0px, 243px, 57px, 1022px, 778px, '../sprite.png', 'btn-menu-mb', );
$btn-menu-selected-mb-name: 'btn-menu-selected-mb';
$btn-menu-selected-mb-x: 779px;
$btn-menu-selected-mb-y: 57px;
$btn-menu-selected-mb-offset-x: -779px;
$btn-menu-selected-mb-offset-y: -57px;
$btn-menu-selected-mb-width: 243px;
$btn-menu-selected-mb-height: 57px;
$btn-menu-selected-mb-total-width: 1022px;
$btn-menu-selected-mb-total-height: 778px;
$btn-menu-selected-mb-image: '../sprite.png';
$btn-menu-selected-mb: (779px, 57px, -779px, -57px, 243px, 57px, 1022px, 778px, '../sprite.png', 'btn-menu-selected-mb', );
$btn-menu-selected-name: 'btn-menu-selected';
$btn-menu-selected-x: 266px;
$btn-menu-selected-y: 580px;
$btn-menu-selected-offset-x: -266px;
$btn-menu-selected-offset-y: -580px;
$btn-menu-selected-width: 282px;
$btn-menu-selected-height: 74px;
$btn-menu-selected-total-width: 1022px;
$btn-menu-selected-total-height: 778px;
$btn-menu-selected-image: '../sprite.png';
$btn-menu-selected: (266px, 580px, -266px, -580px, 282px, 74px, 1022px, 778px, '../sprite.png', 'btn-menu-selected', );
$btn-menu-name: 'btn-menu';
$btn-menu-x: 0px;
$btn-menu-y: 659px;
$btn-menu-offset-x: 0px;
$btn-menu-offset-y: -659px;
$btn-menu-width: 282px;
$btn-menu-height: 74px;
$btn-menu-total-width: 1022px;
$btn-menu-total-height: 778px;
$btn-menu-image: '../sprite.png';
$btn-menu: (0px, 659px, 0px, -659px, 282px, 74px, 1022px, 778px, '../sprite.png', 'btn-menu', );
$btn-pagination-mb-name: 'btn-pagination-mb';
$btn-pagination-mb-x: 0px;
$btn-pagination-mb-y: 429px;
$btn-pagination-mb-offset-x: 0px;
$btn-pagination-mb-offset-y: -429px;
$btn-pagination-mb-width: 229px;
$btn-pagination-mb-height: 151px;
$btn-pagination-mb-total-width: 1022px;
$btn-pagination-mb-total-height: 778px;
$btn-pagination-mb-image: '../sprite.png';
$btn-pagination-mb: (0px, 429px, 0px, -429px, 229px, 151px, 1022px, 778px, '../sprite.png', 'btn-pagination-mb', );
$btn-pagination-selected-mb-name: 'btn-pagination-selected-mb';
$btn-pagination-selected-mb-x: 229px;
$btn-pagination-selected-mb-y: 429px;
$btn-pagination-selected-mb-offset-x: -229px;
$btn-pagination-selected-mb-offset-y: -429px;
$btn-pagination-selected-mb-width: 228px;
$btn-pagination-selected-mb-height: 151px;
$btn-pagination-selected-mb-total-width: 1022px;
$btn-pagination-selected-mb-total-height: 778px;
$btn-pagination-selected-mb-image: '../sprite.png';
$btn-pagination-selected-mb: (229px, 429px, -229px, -429px, 228px, 151px, 1022px, 778px, '../sprite.png', 'btn-pagination-selected-mb', );
$btn-pagination-selected-name: 'btn-pagination-selected';
$btn-pagination-selected-x: 0px;
$btn-pagination-selected-y: 239px;
$btn-pagination-selected-offset-x: 0px;
$btn-pagination-selected-offset-y: -239px;
$btn-pagination-selected-width: 288px;
$btn-pagination-selected-height: 190px;
$btn-pagination-selected-total-width: 1022px;
$btn-pagination-selected-total-height: 778px;
$btn-pagination-selected-image: '../sprite.png';
$btn-pagination-selected: (0px, 239px, 0px, -239px, 288px, 190px, 1022px, 778px, '../sprite.png', 'btn-pagination-selected', );
$btn-pagination-name: 'btn-pagination';
$btn-pagination-x: 288px;
$btn-pagination-y: 239px;
$btn-pagination-offset-x: -288px;
$btn-pagination-offset-y: -239px;
$btn-pagination-width: 288px;
$btn-pagination-height: 190px;
$btn-pagination-total-width: 1022px;
$btn-pagination-total-height: 778px;
$btn-pagination-image: '../sprite.png';
$btn-pagination: (288px, 239px, -288px, -239px, 288px, 190px, 1022px, 778px, '../sprite.png', 'btn-pagination', );
$btn-play-name: 'btn-play';
$btn-play-x: 951px;
$btn-play-y: 207px;
$btn-play-offset-x: -951px;
$btn-play-offset-y: -207px;
$btn-play-width: 56px;
$btn-play-height: 56px;
$btn-play-total-width: 1022px;
$btn-play-total-height: 778px;
$btn-play-image: '../sprite.png';
$btn-play: (951px, 207px, -951px, -207px, 56px, 56px, 1022px, 778px, '../sprite.png', 'btn-play', );
$bullet-icon-name: 'bullet-icon';
$bullet-icon-x: 955px;
$bullet-icon-y: 335px;
$bullet-icon-offset-x: -955px;
$bullet-icon-offset-y: -335px;
$bullet-icon-width: 32px;
$bullet-icon-height: 24px;
$bullet-icon-total-width: 1022px;
$bullet-icon-total-height: 778px;
$bullet-icon-image: '../sprite.png';
$bullet-icon: (955px, 335px, -955px, -335px, 32px, 24px, 1022px, 778px, '../sprite.png', 'bullet-icon', );
$gun-icon-name: 'gun-icon';
$gun-icon-x: 968px;
$gun-icon-y: 266px;
$gun-icon-offset-x: -968px;
$gun-icon-offset-y: -266px;
$gun-icon-width: 40px;
$gun-icon-height: 27px;
$gun-icon-total-width: 1022px;
$gun-icon-total-height: 778px;
$gun-icon-image: '../sprite.png';
$gun-icon: (968px, 266px, -968px, -266px, 40px, 27px, 1022px, 778px, '../sprite.png', 'gun-icon', );
$heart-icon-bk-name: 'heart-icon-bk';
$heart-icon-bk-x: 987px;
$heart-icon-bk-y: 335px;
$heart-icon-bk-offset-x: -987px;
$heart-icon-bk-offset-y: -335px;
$heart-icon-bk-width: 24px;
$heart-icon-bk-height: 22px;
$heart-icon-bk-total-width: 1022px;
$heart-icon-bk-total-height: 778px;
$heart-icon-bk-image: '../sprite.png';
$heart-icon-bk: (987px, 335px, -987px, -335px, 24px, 22px, 1022px, 778px, '../sprite.png', 'heart-icon-bk', );
$heart-icon-name: 'heart-icon';
$heart-icon-x: 922px;
$heart-icon-y: 335px;
$heart-icon-offset-x: -922px;
$heart-icon-offset-y: -335px;
$heart-icon-width: 33px;
$heart-icon-height: 27px;
$heart-icon-total-width: 1022px;
$heart-icon-total-height: 778px;
$heart-icon-image: '../sprite.png';
$heart-icon: (922px, 335px, -922px, -335px, 33px, 27px, 1022px, 778px, '../sprite.png', 'heart-icon', );
$lightning-name: 'lightning';
$lightning-x: 779px;
$lightning-y: 335px;
$lightning-offset-x: -779px;
$lightning-offset-y: -335px;
$lightning-width: 90px;
$lightning-height: 131px;
$lightning-total-width: 1022px;
$lightning-total-height: 778px;
$lightning-image: '../sprite.png';
$lightning: (779px, 335px, -779px, -335px, 90px, 131px, 1022px, 778px, '../sprite.png', 'lightning', );
$swiper-next-mb-name: 'swiper-next-mb';
$swiper-next-mb-x: 951px;
$swiper-next-mb-y: 114px;
$swiper-next-mb-offset-x: -951px;
$swiper-next-mb-offset-y: -114px;
$swiper-next-mb-width: 53px;
$swiper-next-mb-height: 93px;
$swiper-next-mb-total-width: 1022px;
$swiper-next-mb-total-height: 778px;
$swiper-next-mb-image: '../sprite.png';
$swiper-next-mb: (951px, 114px, -951px, -114px, 53px, 93px, 1022px, 778px, '../sprite.png', 'swiper-next-mb', );
$swiper-next-name: 'swiper-next';
$swiper-next-x: 779px;
$swiper-next-y: 114px;
$swiper-next-offset-x: -779px;
$swiper-next-offset-y: -114px;
$swiper-next-width: 86px;
$swiper-next-height: 152px;
$swiper-next-total-width: 1022px;
$swiper-next-total-height: 778px;
$swiper-next-image: '../sprite.png';
$swiper-next: (779px, 114px, -779px, -114px, 86px, 152px, 1022px, 778px, '../sprite.png', 'swiper-next', );
$swiper-prev-mb-name: 'swiper-prev-mb';
$swiper-prev-mb-x: 869px;
$swiper-prev-mb-y: 335px;
$swiper-prev-mb-offset-x: -869px;
$swiper-prev-mb-offset-y: -335px;
$swiper-prev-mb-width: 53px;
$swiper-prev-mb-height: 93px;
$swiper-prev-mb-total-width: 1022px;
$swiper-prev-mb-total-height: 778px;
$swiper-prev-mb-image: '../sprite.png';
$swiper-prev-mb: (869px, 335px, -869px, -335px, 53px, 93px, 1022px, 778px, '../sprite.png', 'swiper-prev-mb', );
$swiper-prev-name: 'swiper-prev';
$swiper-prev-x: 865px;
$swiper-prev-y: 114px;
$swiper-prev-offset-x: -865px;
$swiper-prev-offset-y: -114px;
$swiper-prev-width: 86px;
$swiper-prev-height: 152px;
$swiper-prev-total-width: 1022px;
$swiper-prev-total-height: 778px;
$swiper-prev-image: '../sprite.png';
$swiper-prev: (865px, 114px, -865px, -114px, 86px, 152px, 1022px, 778px, '../sprite.png', 'swiper-prev', );
$target-icon-name: 'target-icon';
$target-icon-x: 968px;
$target-icon-y: 293px;
$target-icon-offset-x: -968px;
$target-icon-offset-y: -293px;
$target-icon-width: 31px;
$target-icon-height: 31px;
$target-icon-total-width: 1022px;
$target-icon-total-height: 778px;
$target-icon-image: '../sprite.png';
$target-icon: (968px, 293px, -968px, -293px, 31px, 31px, 1022px, 778px, '../sprite.png', 'target-icon', );
$weapon-detail-bg-mb-name: 'weapon-detail-bg-mb';
$weapon-detail-bg-mb-x: 425px;
$weapon-detail-bg-mb-y: 0px;
$weapon-detail-bg-mb-offset-x: -425px;
$weapon-detail-bg-mb-offset-y: 0px;
$weapon-detail-bg-mb-width: 354px;
$weapon-detail-bg-mb-height: 199px;
$weapon-detail-bg-mb-total-width: 1022px;
$weapon-detail-bg-mb-total-height: 778px;
$weapon-detail-bg-mb-image: '../sprite.png';
$weapon-detail-bg-mb: (425px, 0px, -425px, 0px, 354px, 199px, 1022px, 778px, '../sprite.png', 'weapon-detail-bg-mb', );
$weapon-detail-bg-name: 'weapon-detail-bg';
$weapon-detail-bg-x: 0px;
$weapon-detail-bg-y: 0px;
$weapon-detail-bg-offset-x: 0px;
$weapon-detail-bg-offset-y: 0px;
$weapon-detail-bg-width: 425px;
$weapon-detail-bg-height: 239px;
$weapon-detail-bg-total-width: 1022px;
$weapon-detail-bg-total-height: 778px;
$weapon-detail-bg-image: '../sprite.png';
$weapon-detail-bg: (0px, 0px, 0px, 0px, 425px, 239px, 1022px, 778px, '../sprite.png', 'weapon-detail-bg', );
$weapon-row-dark-mb-name: 'weapon-row-dark-mb';
$weapon-row-dark-mb-x: 407px;
$weapon-row-dark-mb-y: 733px;
$weapon-row-dark-mb-offset-x: -407px;
$weapon-row-dark-mb-offset-y: -733px;
$weapon-row-dark-mb-width: 339px;
$weapon-row-dark-mb-height: 38px;
$weapon-row-dark-mb-total-width: 1022px;
$weapon-row-dark-mb-total-height: 778px;
$weapon-row-dark-mb-image: '../sprite.png';
$weapon-row-dark-mb: (407px, 733px, -407px, -733px, 339px, 38px, 1022px, 778px, '../sprite.png', 'weapon-row-dark-mb', );
$weapon-row-dark-name: 'weapon-row-dark';
$weapon-row-dark-x: 282px;
$weapon-row-dark-y: 659px;
$weapon-row-dark-offset-x: -282px;
$weapon-row-dark-offset-y: -659px;
$weapon-row-dark-width: 407px;
$weapon-row-dark-height: 46px;
$weapon-row-dark-total-width: 1022px;
$weapon-row-dark-total-height: 778px;
$weapon-row-dark-image: '../sprite.png';
$weapon-row-dark: (282px, 659px, -282px, -659px, 407px, 46px, 1022px, 778px, '../sprite.png', 'weapon-row-dark', );
$weapon-row-light-mb-name: 'weapon-row-light-mb';
$weapon-row-light-mb-x: 425px;
$weapon-row-light-mb-y: 199px;
$weapon-row-light-mb-offset-x: -425px;
$weapon-row-light-mb-offset-y: -199px;
$weapon-row-light-mb-width: 339px;
$weapon-row-light-mb-height: 39px;
$weapon-row-light-mb-total-width: 1022px;
$weapon-row-light-mb-total-height: 778px;
$weapon-row-light-mb-image: '../sprite.png';
$weapon-row-light-mb: (425px, 199px, -425px, -199px, 339px, 39px, 1022px, 778px, '../sprite.png', 'weapon-row-light-mb', );
$weapon-row-light-name: 'weapon-row-light';
$weapon-row-light-x: 0px;
$weapon-row-light-y: 733px;
$weapon-row-light-offset-x: 0px;
$weapon-row-light-offset-y: -733px;
$weapon-row-light-width: 407px;
$weapon-row-light-height: 45px;
$weapon-row-light-total-width: 1022px;
$weapon-row-light-total-height: 778px;
$weapon-row-light-image: '../sprite.png';
$weapon-row-light: (0px, 733px, 0px, -733px, 407px, 45px, 1022px, 778px, '../sprite.png', 'weapon-row-light', );
$spritesheet-width: 1022px;
$spritesheet-height: 778px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($btn-character-mb, $btn-character-selected-mb, $btn-character-selected, $btn-character, $btn-menu-mb, $btn-menu-selected-mb, $btn-menu-selected, $btn-menu, $btn-pagination-mb, $btn-pagination-selected-mb, $btn-pagination-selected, $btn-pagination, $btn-play, $bullet-icon, $gun-icon, $heart-icon-bk, $heart-icon, $lightning, $swiper-next-mb, $swiper-next, $swiper-prev-mb, $swiper-prev, $target-icon, $weapon-detail-bg-mb, $weapon-detail-bg, $weapon-row-dark-mb, $weapon-row-dark, $weapon-row-light-mb, $weapon-row-light, );
$spritesheet: (1022px, 778px, '../sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
