@use "../../main/scss/mixin" as *;
@import "sprite.scss";

#guide {
	.section__background {
		img {
			width: 100%;
			height: 100%;
		}
	}

	.section__content {
		.title {
			position: absolute;
			top: 146px;
			left: 50%;
			translate: -50% 0;
		}
		.news-bg {
			background-image: url("../images/news-bg.png");
			width: 1131px;
			height: 1193px;
			position: absolute;
			top: 287px;
			left: 50%;
			translate: -50% 0;
			background-size: 100% 100%;

			@include mobile {
				width: 735px;
				height: 1241px;
			}
			.top-menu {
				$accent-color: #e7dcc3;
				position: absolute;
				top: 8px;
				right: 8px;
				width: 100%;
				padding-left: 40px;
				padding-right: 5px;
				height: 57px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.nav-container {
					flex-shrink: 0;
					display: flex;
					& button,
					& .btn {
						@include spriteBg;
						font-family: utm-talling;
						font-weight: 700;
						text-transform: uppercase;
						padding-bottom: 4px;
						font-size: 36px;
						flex-shrink: 0;
						min-width: 127px;
						padding-inline: 16px;
						height: 44px;
						color: $accent-color;
						&.active {
							background-image: url("../images/menu-selected.png");
							background-size: 100% 100%;
							color: black;
						}

						&:hover {
							color: #ed7f26;
						}
					}
				}

				.search-form {
					@include sprite($search);
					display: flex;
					justify-content: space-between;
					align-items: center;
					input {
						height: 42px;
						width: 197px;
						padding: 8px;
						accent-color: $accent-color;
						color: $accent-color;
					}

					.btn-search {
						@include hoverDark();
						transition: filter 200ms ease-in-out;
						width: 64px;
						height: 50px;
						display: flex;
						justify-content: center;
						align-items: center;
						.search-icon {
							@include sprite($search-icon);
						}
					}
				}
			}

			.news-container {
				width: 1086px;
				height: 1082px;
				position: absolute;
				bottom: 40px;
				left: 50.5%;
				translate: -50% 0;

				@include mobile {
					width: 690px;
					bottom: 85px;
				}

				.custom-news-select {
					z-index: 999;
					@include sprite($select);
					position: relative;
					display: flex;
					align-items: center;
					padding-inline: 21px;
					margin-top: 18px;
					margin-left: 30px;
					font-size: 18px;
					font-family: roboto;
					font-weight: 500;

					@include mobile {
						margin-left: 0;
					}

					select {
						display: none;
						pointer-events: none;
					}

					.arrow {
						@include sprite($arrow);
						position: absolute;
						top: 50%;
						right: 13px;
						translate: 0 -50%;
						rotate: 180deg;
						transition: rotate 200ms ease-in-out;
					}

					.select-item-list {
						position: absolute;
						width: 100%;
						top: 100%;
						left: 0;
						background-color: #d8d1c3;
						display: flex;
						flex-direction: column;
						max-height: 0;
						overflow: hidden;
						// transition: all 0.3s linear;
						.select-item {
							cursor: pointer;
							height: 42px;
							display: flex;
							align-items: center;
							padding-inline: 21px;
							&:hover {
								background-color: rgb(0, 0, 0, 0.3);
							}
						}
					}

					&.opened {
						.arrow {
							rotate: 0deg;
						}
						.select-item-list {
							max-height: 9999px;
						}
					}
				}

				.news-list {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					.news {
						flex-shrink: 0;
						padding-top: 19px;
						padding-bottom: 21px;
						position: relative;

						// &.is-hot {
						// 	.news-inner .content-container .news-title .hot {
						// 		display: block;
						// 	}
						// }

						&::after {
							content: "";
							width: 100%;
							height: 2px;
							position: absolute;
							bottom: 0;
							left: 0;
							background-color: #cbbda5;
						}

						.news-inner {
							width: 1023px;
							height: 144px;
							position: relative;

							@include mobile {
								width: 690px;
							}

							.news-img {
								width: 233px;
								height: 144px;
								position: absolute;
								top: 0;
								left: 0;
								object-fit: cover;
							}

							.content-container {
								font-family: roboto;
								width: 545px;
								height: 144px;
								position: absolute;
								left: 267px;
								top: 0;

								@include mobile {
									width: 424px;
								}

								.news-title {
									position: relative;
									//margin-top: 16px;
									margin-bottom: 8px;
									font-weight: 700;
									font-size: 19px;
									display: flex;
									align-items: center;
									justify-content: flex-start;
									gap: 4px;
									color: black;
									.hot {
										@include sprite($hot);
										//display: none;
									}
								}

								.news-content {
									font-weight: 400;
									font-size: 17px;
									@include mobile {
										line-height: 20px;
										padding-right: 20px;
										max-height: 58px;
										overflow: hidden;
									}
								}

								.news-date {
									@include sprite($date-container);
									@include spriteBg;
									position: absolute;
									bottom: 0;
									left: 0;
									color: white;
								}
							}

							.btn-more {
								@include sprite($btn-yellow);
								@include hoverLight();
								position: absolute;
								right: 0;
								top: 50%;
								translate: 0 -50%;
								color: black;
								font-family: utm-talling;
								font-weight: 700;
								font-size: 36px;
								padding-bottom: 12px;
								display: flex;
								justify-content: center;
								align-items: center;
								@include mobile {
									@include sprite($btn-yellow-mb);
									top: 93%;
									right: 30px;
									font-size: 28px;
									padding-bottom: 8px;
								}
							}
						}
					}

					& .news:last-child::after {
						display: none;
					}
				}

				.pagination-wrapper {
					width: 100%;
					position: absolute;
					bottom: 21px;
					left: 0;
					display: flex;
					justify-content: center;
					gap: 8px;
					.page-link,
					.current,
					.btn {
						@include sprite($pagination);
						@include spriteBg;
						user-select: none;
						color: black;
						font-size: 20px;
					}

					.btn {
						&.disabled {
							opacity: 0.4;
							pointer-events: none;
						}
					}

					.pagination {
						ul {
							display: flex;
							justify-content: center;
							gap: 7px;
							li {
								span,
								a {
									font-family: roboto;
									font-weight: 500;
									font-size: 16px;
								}

								.ellipse {
									font-size: 20px;
								}

								&.disabled {
									opacity: 0.4;
								}
								&.active {
									.page-link {
										@include sprite($pagination-selected);
										cursor: pointer;
										display: block;
										display: flex;
										justify-content: center;
										align-items: center;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.th #guide {
	.section__content {
		.top-menu {
			.nav-container {
				button {
					font-family: sukhumvitset;
					font-weight: 700;
					font-size: 26px;
				}
			}
			.search-form {
				input {
					font-family: sukhumvitset;
					font-weight: 400;
				}
			}
		}
		.news-container {
			.custom-news-select {
				font-family: sukhumvitset;
			}
		}
		.news-bg .news-container .news-list .news .news-inner .btn-more {
			font-family: sukhumvitset;
			font-size: 27.7px;
			padding-bottom: 0;
		}

		.news-bg .news-container .news-list .news .news-inner .content-container {
			.news-title {
				font-family: sukhumvitset;
				font-size: 22px;

				@include mobile {
					font-size: 16px;
				}
			}
			.news-content {
				font-family: sukhumvitset;
			}
		}
	}
}

.tw #guide {
	.section__content {
		.top-menu {
			.nav-container {
				button {
					font-family: chineseH;
					min-width: 100px;
					font-size: 36px;
				}
			}
			.search-form {
				input {
					font-family: chineseH;
					font-size: 24px;
				}
			}
		}
		.news-container {
			.custom-news-select {
				font-family: chineseH;
				font-size: 18px;
			}
		}
		.news-bg .news-container .news-list .news .news-inner .btn-more {
			font-family: chineseH;
			font-size: 27.7px;
		}

		.news-bg .news-container .news-list .news .news-inner .content-container {
			.news-title {
				font-family: chineseH;
				font-size: 22px;
				font-weight: 400;

				@include mobile {
					font-size: 16px;
				}
			}
			.news-content {
				font-family: chineseH;
			}
		}
	}
}
