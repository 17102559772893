// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$arrow-name: 'arrow';
$arrow-x: 218px;
$arrow-y: 157px;
$arrow-offset-x: -218px;
$arrow-offset-y: -157px;
$arrow-width: 24px;
$arrow-height: 14px;
$arrow-total-width: 383px;
$arrow-total-height: 209px;
$arrow-image: '../sprite.png';
$arrow: (218px, 157px, -218px, -157px, 24px, 14px, 383px, 209px, '../sprite.png', 'arrow', );
$man-mb-name: 'man-mb';
$man-mb-x: 218px;
$man-mb-y: 0px;
$man-mb-offset-x: -218px;
$man-mb-offset-y: 0px;
$man-mb-width: 165px;
$man-mb-height: 157px;
$man-mb-total-width: 383px;
$man-mb-total-height: 209px;
$man-mb-image: '../sprite.png';
$man-mb: (218px, 0px, -218px, 0px, 165px, 157px, 383px, 209px, '../sprite.png', 'man-mb', );
$man-name: 'man';
$man-x: 0px;
$man-y: 0px;
$man-offset-x: 0px;
$man-offset-y: 0px;
$man-width: 218px;
$man-height: 209px;
$man-total-width: 383px;
$man-total-height: 209px;
$man-image: '../sprite.png';
$man: (0px, 0px, 0px, 0px, 218px, 209px, 383px, 209px, '../sprite.png', 'man', );
$spritesheet-width: 383px;
$spritesheet-height: 209px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($arrow, $man-mb, $man, );
$spritesheet: (383px, 209px, '../sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
