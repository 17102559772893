.layer {
	width: 100%;
	height: 100%;
	background-color: black;
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 99999;
	opacity: 1;
	transition: opacity 0.5s linear;

	.loading-container {
		transform-origin: center center !important;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	.tank-gif {
		background-image: url("https://global-mainsite.mto.zing.vn/products/metalslug/item-loading/layerLoad.gif");
		//background-size: 100% 100%;
		//aspect-ratio: 783/528;
		width: 217px;
		height: 188px;
		transform-origin: center center;
		//scale: 0.8;
	}
	@media (max-width: 1200px) {
		.tank-gif {
			background-image: url("https://global-mainsite.mto.zing.vn/products/metalslug/item-loading/layerLoad.gif");
			// background-size: 100% 100%;
			// aspect-ratio: 217/188;
			// width: 217px;
			// height: 188px;
			transform-origin: center center;
			scale: 0.8;
		}
	}
	@media (max-width: 768px) {
		.tank-gif {
			background-image: url("https://global-mainsite.mto.zing.vn/products/metalslug/item-loading/layerLoad.gif");
			// background-size: 100% 100%;
			// aspect-ratio: 217/188;
			// width: 217px;
			// height: 188px;
			transform-origin: center center;
			scale: 0.6;
		}
	}
	.text,
	.dot-container div {
		color: white;
		font-size: 100px;
		font-family: utm-talling;
	}
	.text {
		display: flex;
		gap: 20px;
		.dot-container {
			display: flex;
			gap: 8px;

			& div {
				$duration: 1.5s;
				$wait: 0.2s;
				opacity: 0;

				&:nth-child(1) {
					animation: appear $duration 0s ease-in infinite;
				}
				&:nth-child(2) {
					animation: appear $duration $wait ease-in infinite;
				}
				&:nth-child(3) {
					animation: appear $duration 2 * $wait ease-in infinite;
				}
			}
		}
	}
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes appear {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
