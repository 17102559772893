// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$apk-en-name: 'apk-en';
$apk-en-x: 462px;
$apk-en-y: 503px;
$apk-en-offset-x: -462px;
$apk-en-offset-y: -503px;
$apk-en-width: 150px;
$apk-en-height: 46px;
$apk-en-total-width: 764px;
$apk-en-total-height: 613px;
$apk-en-image: '../sprite.png';
$apk-en: (462px, 503px, -462px, -503px, 150px, 46px, 764px, 613px, '../sprite.png', 'apk-en', );
$apk-th-name: 'apk-th';
$apk-th-x: 0px;
$apk-th-y: 567px;
$apk-th-offset-x: 0px;
$apk-th-offset-y: -567px;
$apk-th-width: 150px;
$apk-th-height: 46px;
$apk-th-total-width: 764px;
$apk-th-total-height: 613px;
$apk-th-image: '../sprite.png';
$apk-th: (0px, 567px, 0px, -567px, 150px, 46px, 764px, 613px, '../sprite.png', 'apk-th', );
$apk-tw-name: 'apk-tw';
$apk-tw-x: 150px;
$apk-tw-y: 567px;
$apk-tw-offset-x: -150px;
$apk-tw-offset-y: -567px;
$apk-tw-width: 150px;
$apk-tw-height: 46px;
$apk-tw-total-width: 764px;
$apk-tw-total-height: 613px;
$apk-tw-image: '../sprite.png';
$apk-tw: (150px, 567px, -150px, -567px, 150px, 46px, 764px, 613px, '../sprite.png', 'apk-tw', );
$appstore-en-name: 'appstore-en';
$appstore-en-x: 300px;
$appstore-en-y: 567px;
$appstore-en-offset-x: -300px;
$appstore-en-offset-y: -567px;
$appstore-en-width: 150px;
$appstore-en-height: 46px;
$appstore-en-total-width: 764px;
$appstore-en-total-height: 613px;
$appstore-en-image: '../sprite.png';
$appstore-en: (300px, 567px, -300px, -567px, 150px, 46px, 764px, 613px, '../sprite.png', 'appstore-en', );
$appstore-th-name: 'appstore-th';
$appstore-th-x: 450px;
$appstore-th-y: 567px;
$appstore-th-offset-x: -450px;
$appstore-th-offset-y: -567px;
$appstore-th-width: 150px;
$appstore-th-height: 46px;
$appstore-th-total-width: 764px;
$appstore-th-total-height: 613px;
$appstore-th-image: '../sprite.png';
$appstore-th: (450px, 567px, -450px, -567px, 150px, 46px, 764px, 613px, '../sprite.png', 'appstore-th', );
$appstore-tw-name: 'appstore-tw';
$appstore-tw-x: 614px;
$appstore-tw-y: 0px;
$appstore-tw-offset-x: -614px;
$appstore-tw-offset-y: 0px;
$appstore-tw-width: 150px;
$appstore-tw-height: 46px;
$appstore-tw-total-width: 764px;
$appstore-tw-total-height: 613px;
$appstore-tw-image: '../sprite.png';
$appstore-tw: (614px, 0px, -614px, 0px, 150px, 46px, 764px, 613px, '../sprite.png', 'appstore-tw', );
$arrow-icon-name: 'arrow-icon';
$arrow-icon-x: 687px;
$arrow-icon-y: 184px;
$arrow-icon-offset-x: -687px;
$arrow-icon-offset-y: -184px;
$arrow-icon-width: 39px;
$arrow-icon-height: 42px;
$arrow-icon-total-width: 764px;
$arrow-icon-total-height: 613px;
$arrow-icon-image: '../sprite.png';
$arrow-icon: (687px, 184px, -687px, -184px, 39px, 42px, 764px, 613px, '../sprite.png', 'arrow-icon', );
$btn-download-name: 'btn-download';
$btn-download-x: 0px;
$btn-download-y: 0px;
$btn-download-offset-x: 0px;
$btn-download-offset-y: 0px;
$btn-download-width: 306px;
$btn-download-height: 102px;
$btn-download-total-width: 764px;
$btn-download-total-height: 613px;
$btn-download-image: '../sprite.png';
$btn-download: (0px, 0px, 0px, 0px, 306px, 102px, 764px, 613px, '../sprite.png', 'btn-download', );
$bullet-hole-name: 'bullet-hole';
$bullet-hole-x: 614px;
$bullet-hole-y: 184px;
$bullet-hole-offset-x: -614px;
$bullet-hole-offset-y: -184px;
$bullet-hole-width: 73px;
$bullet-hole-height: 76px;
$bullet-hole-total-width: 764px;
$bullet-hole-total-height: 613px;
$bullet-hole-image: '../sprite.png';
$bullet-hole: (614px, 184px, -614px, -184px, 73px, 76px, 764px, 613px, '../sprite.png', 'bullet-hole', );
$code-en-name: 'code-en';
$code-en-x: 0px;
$code-en-y: 102px;
$code-en-offset-x: 0px;
$code-en-offset-y: -102px;
$code-en-width: 154px;
$code-en-height: 155px;
$code-en-total-width: 764px;
$code-en-total-height: 613px;
$code-en-image: '../sprite.png';
$code-en: (0px, 102px, 0px, -102px, 154px, 155px, 764px, 613px, '../sprite.png', 'code-en', );
$code-id-name: 'code-id';
$code-id-x: 306px;
$code-id-y: 0px;
$code-id-offset-x: -306px;
$code-id-offset-y: 0px;
$code-id-width: 154px;
$code-id-height: 155px;
$code-id-total-width: 764px;
$code-id-total-height: 613px;
$code-id-image: '../sprite.png';
$code-id: (306px, 0px, -306px, 0px, 154px, 155px, 764px, 613px, '../sprite.png', 'code-id', );
$code-th-name: 'code-th';
$code-th-x: 0px;
$code-th-y: 257px;
$code-th-offset-x: 0px;
$code-th-offset-y: -257px;
$code-th-width: 154px;
$code-th-height: 155px;
$code-th-total-width: 764px;
$code-th-total-height: 613px;
$code-th-image: '../sprite.png';
$code-th: (0px, 257px, 0px, -257px, 154px, 155px, 764px, 613px, '../sprite.png', 'code-th', );
$code-tw-name: 'code-tw';
$code-tw-x: 154px;
$code-tw-y: 257px;
$code-tw-offset-x: -154px;
$code-tw-offset-y: -257px;
$code-tw-width: 154px;
$code-tw-height: 155px;
$code-tw-total-width: 764px;
$code-tw-total-height: 613px;
$code-tw-image: '../sprite.png';
$code-tw: (154px, 257px, -154px, -257px, 154px, 155px, 764px, 613px, '../sprite.png', 'code-tw', );
$code-vi-name: 'code-vi';
$code-vi-x: 460px;
$code-vi-y: 0px;
$code-vi-offset-x: -460px;
$code-vi-offset-y: 0px;
$code-vi-width: 154px;
$code-vi-height: 155px;
$code-vi-total-width: 764px;
$code-vi-total-height: 613px;
$code-vi-image: '../sprite.png';
$code-vi: (460px, 0px, -460px, 0px, 154px, 155px, 764px, 613px, '../sprite.png', 'code-vi', );
$game-icon-name: 'game-icon';
$game-icon-x: 154px;
$game-icon-y: 102px;
$game-icon-offset-x: -154px;
$game-icon-offset-y: -102px;
$game-icon-width: 145px;
$game-icon-height: 145px;
$game-icon-total-width: 764px;
$game-icon-total-height: 613px;
$game-icon-image: '../sprite.png';
$game-icon: (154px, 102px, -154px, -102px, 145px, 145px, 764px, 613px, '../sprite.png', 'game-icon', );
$googleplay-en-name: 'googleplay-en';
$googleplay-en-x: 614px;
$googleplay-en-y: 46px;
$googleplay-en-offset-x: -614px;
$googleplay-en-offset-y: -46px;
$googleplay-en-width: 150px;
$googleplay-en-height: 46px;
$googleplay-en-total-width: 764px;
$googleplay-en-total-height: 613px;
$googleplay-en-image: '../sprite.png';
$googleplay-en: (614px, 46px, -614px, -46px, 150px, 46px, 764px, 613px, '../sprite.png', 'googleplay-en', );
$googleplay-th-name: 'googleplay-th';
$googleplay-th-x: 614px;
$googleplay-th-y: 92px;
$googleplay-th-offset-x: -614px;
$googleplay-th-offset-y: -92px;
$googleplay-th-width: 150px;
$googleplay-th-height: 46px;
$googleplay-th-total-width: 764px;
$googleplay-th-total-height: 613px;
$googleplay-th-image: '../sprite.png';
$googleplay-th: (614px, 92px, -614px, -92px, 150px, 46px, 764px, 613px, '../sprite.png', 'googleplay-th', );
$googleplay-tw-name: 'googleplay-tw';
$googleplay-tw-x: 614px;
$googleplay-tw-y: 138px;
$googleplay-tw-offset-x: -614px;
$googleplay-tw-offset-y: -138px;
$googleplay-tw-width: 150px;
$googleplay-tw-height: 46px;
$googleplay-tw-total-width: 764px;
$googleplay-tw-total-height: 613px;
$googleplay-tw-image: '../sprite.png';
$googleplay-tw: (614px, 138px, -614px, -138px, 150px, 46px, 764px, 613px, '../sprite.png', 'googleplay-tw', );
$tooltip-mb-name: 'tooltip-mb';
$tooltip-mb-x: 462px;
$tooltip-mb-y: 412px;
$tooltip-mb-offset-x: -462px;
$tooltip-mb-offset-y: -412px;
$tooltip-mb-width: 96px;
$tooltip-mb-height: 91px;
$tooltip-mb-total-width: 764px;
$tooltip-mb-total-height: 613px;
$tooltip-mb-image: '../sprite.png';
$tooltip-mb: (462px, 412px, -462px, -412px, 96px, 91px, 764px, 613px, '../sprite.png', 'tooltip-mb', );
$tooltip-name: 'tooltip';
$tooltip-x: 308px;
$tooltip-y: 257px;
$tooltip-offset-x: -308px;
$tooltip-offset-y: -257px;
$tooltip-width: 139px;
$tooltip-height: 127px;
$tooltip-total-width: 764px;
$tooltip-total-height: 613px;
$tooltip-image: '../sprite.png';
$tooltip: (308px, 257px, -308px, -257px, 139px, 127px, 764px, 613px, '../sprite.png', 'tooltip', );
$topup-en-name: 'topup-en';
$topup-en-x: 460px;
$topup-en-y: 155px;
$topup-en-offset-x: -460px;
$topup-en-offset-y: -155px;
$topup-en-width: 154px;
$topup-en-height: 155px;
$topup-en-total-width: 764px;
$topup-en-total-height: 613px;
$topup-en-image: '../sprite.png';
$topup-en: (460px, 155px, -460px, -155px, 154px, 155px, 764px, 613px, '../sprite.png', 'topup-en', );
$topup-my-name: 'topup-my';
$topup-my-x: 460px;
$topup-my-y: 310px;
$topup-my-offset-x: -460px;
$topup-my-offset-y: -310px;
$topup-my-width: 154px;
$topup-my-height: 78px;
$topup-my-total-width: 764px;
$topup-my-total-height: 613px;
$topup-my-image: '../sprite.png';
$topup-my: (460px, 310px, -460px, -310px, 154px, 78px, 764px, 613px, '../sprite.png', 'topup-my', );
$topup-sg-name: 'topup-sg';
$topup-sg-x: 306px;
$topup-sg-y: 155px;
$topup-sg-offset-x: -306px;
$topup-sg-offset-y: -155px;
$topup-sg-width: 154px;
$topup-sg-height: 78px;
$topup-sg-total-width: 764px;
$topup-sg-total-height: 613px;
$topup-sg-image: '../sprite.png';
$topup-sg: (306px, 155px, -306px, -155px, 154px, 78px, 764px, 613px, '../sprite.png', 'topup-sg', );
$topup-th-name: 'topup-th';
$topup-th-x: 0px;
$topup-th-y: 412px;
$topup-th-offset-x: 0px;
$topup-th-offset-y: -412px;
$topup-th-width: 154px;
$topup-th-height: 155px;
$topup-th-total-width: 764px;
$topup-th-total-height: 613px;
$topup-th-image: '../sprite.png';
$topup-th: (0px, 412px, 0px, -412px, 154px, 155px, 764px, 613px, '../sprite.png', 'topup-th', );
$topup-tw-name: 'topup-tw';
$topup-tw-x: 154px;
$topup-tw-y: 412px;
$topup-tw-offset-x: -154px;
$topup-tw-offset-y: -412px;
$topup-tw-width: 154px;
$topup-tw-height: 155px;
$topup-tw-total-width: 764px;
$topup-tw-total-height: 613px;
$topup-tw-image: '../sprite.png';
$topup-tw: (154px, 412px, -154px, -412px, 154px, 155px, 764px, 613px, '../sprite.png', 'topup-tw', );
$topup-vi-name: 'topup-vi';
$topup-vi-x: 308px;
$topup-vi-y: 412px;
$topup-vi-offset-x: -308px;
$topup-vi-offset-y: -412px;
$topup-vi-width: 154px;
$topup-vi-height: 155px;
$topup-vi-total-width: 764px;
$topup-vi-total-height: 613px;
$topup-vi-image: '../sprite.png';
$topup-vi: (308px, 412px, -308px, -412px, 154px, 155px, 764px, 613px, '../sprite.png', 'topup-vi', );
$spritesheet-width: 764px;
$spritesheet-height: 613px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($apk-en, $apk-th, $apk-tw, $appstore-en, $appstore-th, $appstore-tw, $arrow-icon, $btn-download, $bullet-hole, $code-en, $code-id, $code-th, $code-tw, $code-vi, $game-icon, $googleplay-en, $googleplay-th, $googleplay-tw, $tooltip-mb, $tooltip, $topup-en, $topup-my, $topup-sg, $topup-th, $topup-tw, $topup-vi, );
$spritesheet: (764px, 613px, '../sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
