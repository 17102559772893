@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import "swiper/scss/effect-coverflow";

@font-face {
	font-family: beVietnam;
	src: url("../font/BeVietnamPro-Regular.ttf");
	font-weight: 500;
}

@font-face {
	font-family: beVietnam;
	src: url("../font/BeVietnamPro-Bold.ttf");
	font-weight: 700;
}

@font-face {
	font-family: beVietnam;
	src: url("../font/BeVietnamPro-ExtraBold.ttf");
	font-weight: 800;
}

@font-face {
	font-family: utm-talling;
	src: url("../font/UTMTalling.ttf");
}

@font-face {
	font-family: utm-talling;
	src: url("../font/UTMTallingBold.ttf");
	font-weight: 700;
}

@font-face {
	font-family: roboto;
	src: url("../font/Roboto-Light.ttf");
	font-weight: 300;
}

@font-face {
	font-family: roboto;
	src: url("../font/Roboto-Regular.ttf");
	font-weight: 400;
}

@font-face {
	font-family: roboto;
	src: url("../font/Roboto-Medium.ttf");
	font-weight: 500;
}

@font-face {
	font-family: roboto;
	src: url("../font/Roboto-Bold.ttf");
	font-weight: 700;
}

@font-face {
	font-family: barlow;
	src: url("../font/Barlow-Regular.ttf");
	font-weight: 400;
}

@font-face {
	font-family: sukhumvitset;
	src: url("../font/sukhumvit-set_text.ttf");
	font-weight: 400;
}

@font-face {
	font-family: sukhumvitset;
	src: url("../font/sukhumvit-set_medium.ttf");
	font-weight: 5s00;
}

@font-face {
	font-family: sukhumvitset;
	src: url("../font/sukhumvit-set_bold.ttf");
	font-weight: 700;
}

@font-face {
	font-family: chineseH;
	src: url("../font/FZLTH.ttf");
}

@font-face {
	font-family: chinseseY;
	src: url("../font/FZLTY.ttf");
}
