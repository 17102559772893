@import "../../main/scss/mixin";
@import "sprite.scss";

#float_top {
	.float__content {
		top: 0;
		left: 0;
		width: var(--width-desktop);
		height: 120px;
		position: relative;

		.bg-img {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
		}

		.metalslug-icon {
			@include sprite($game-icon);
			@include hoverLight();
			@include spriteImg;
			position: absolute;
			z-index: 1;
			top: 5px;
			left: 54px;
		}

		.navigation {
			font-family: utm-talling;
			font-weight: 700;
			text-transform: uppercase;
			@include textBorder(2px, #000);
			font-size: 40px;
			position: absolute;
			left: 231px;
			top: 50%;
			translate: 0 -50%;
			z-index: 1;
			.list-container {
				display: flex;
				align-items: center;
				.menu-item {
					@include hoverLight();
					transition: filter 200ms ease-in-out;
					flex-shrink: 0;
					color: #f7f5ed;
					padding: 4px 36px;

					&.active {
						background-image: url("../images/btn-active-ns.png");
						background-size: 100% 100%;
					}
				}
			}
		}

		.language {
			@include sprite($language-icon);
			@include hoverLight();
			transition: filter 200ms ease-in-out;
			position: absolute;
			top: 14px;
			right: 104px;
			display: flex;
			align-items: center;
			z-index: 200;
			font-family: utm-talling;
			@include textBorder(3px, #000);
			input {
				width: 100%;
				height: 100%;
				cursor: pointer;
			}

			.floattop__item--language {
				display: flex;
				align-items: center;
				justify-content: center;
				background-repeat: no-repeat;
				&:after {
					width: 16px;
					height: 8px;
					content: "";
					display: block;
					position: absolute;
					top: 50%;
					right: 5px;
					transform: translateY(-50%);
				}
			}

			&__list {
				@include sprite($language-dropdown);
				position: absolute;
				top: 92px;
				left: 50%;
				translate: -50% 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				max-height: 0;
				overflow: hidden;
				transition: all 0.4s;

				.dropdown-content {
					width: 100%;
					position: relative;
					top: 38px;
				}
			}

			&__item {
				height: 64px;
				text-align: center;
				padding: 6px 8px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				font-size: 36px;

				&::after {
					content: "";
					width: 90%;
					height: 2px;
					background-color: #282222;
					position: absolute;
					bottom: 0;
					left: 50%;
					translate: -50% 0;
				}

				&:last-child::after {
					display: none;
				}

				a {
					display: flex !important;
					align-items: center;
					justify-content: center;
					color: #ffefd6;
					width: 100%;
					height: 100%;
				}
				&:hover,
				&.active {
					cursor: pointer;
					background: rgb(55, 46, 42);
				}
			}

			input:checked + label + .language__list {
				max-height: 999px;
				padding: 1px;
			}
		}

		&.mb {
			width: var(--width-mobile);

			.game-icon {
				@include sprite($game-icon-mb);
				@include spriteImg;
				position: absolute;
				left: 14px;
				top: 10px;
			}

			.btn-container {
				position: absolute;
				right: 16px;
				top: 24px;
				display: flex;
				gap: 5px;
				.btn {
					display: block;
					@include sprite($btn-yellow);
					@include hoverLight();
					position: relative;
					&.btn-topup {
						.coins-icon {
							@include sprite($coins);
							display: inline-block;
							position: absolute;
							bottom: 8px;
						}
						.text {
							position: absolute;
							right: 16px;
							top: 50%;
							translate: 0 -50%;
						}
					}
					&.btn-code {
						.present-icon {
							@include sprite($present);
							display: inline-block;
							position: absolute;
							bottom: 8px;
						}
						.text {
							position: absolute;
							right: 16px;
							top: 50%;
							translate: 0 -50%;
							text-align: center;
						}
					}

					.text {
						color: black;
						font-family: roboto;
						font-weight: 700;
						font-size: 22px;
						line-height: 20px;
					}
				}
			}
		}
	}
}

.vn #float_top {
	.float__content {
		.navigation {
		}

		.language {
		}

		.btn-container {
			.btn {
				&.btn-topup {
					.text {
						position: absolute;
						right: 12px;
					}
				}
				&.btn-code {
					.present-icon {
						left: 16px;
					}
					.text {
						right: 32px;
					}
				}

				.text {
					font-size: 20px;
				}
			}
		}
	}
}

.id #float_top {
	.float__content {
		.navigation {
		}

		.language {
		}

		.btn-container {
			.btn {
				&.btn-code {
					.present-icon {
						left: 16px;
					}
					.text {
						text-align: center;
					}
				}
			}
		}
	}
}

.th #float_top {
	.float__content {
		.navigation {
			font-family: sukhumvitset;
			font-weight: 700;
			font-size: 36px;

			.utm {
				font-family: utm-talling !important;
			}
		}

		.language {
		}

		.btn-container {
			.btn {
				&.btn-code {
					.present-icon {
						left: 16px;
					}
				}
				.text {
					font-family: sukhumvitset;
					font-weight: 700;
					font-size: 24px;
					text-align: center;
				}
			}
		}
	}
}

.tw #float_top {
	.float__content {
		.metalslug-icon {
			@include sprite($game-icon-tw);
			top: 8px;
			left: 12px;
		}
		.navigation {
			left: 245px;
			font-family: chineseH;
			font-size: 48px;
			font-weight: 400;
			top: 47%;
			.utm {
				font-family: utm-talling !important;
				font-size: 40px !important;
			}
		}

		.btn-container {
			.btn {
				&.btn-topup {
					.coins-icon {
						left: 12px;
					}
					.text {
						right: 24px;
					}
				}
				&.btn-code {
					.present-icon {
						left: 8px;
					}
					.text {
					}
				}
				.text {
					font-family: chineseH;
					text-align: center;
					//font-weight: 400;
					font-size: 24px;
				}
			}
		}
	}
}
