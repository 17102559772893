@import "../../main/scss/mixin";
@import "sprite.scss";

#block3 {
	.section__background {
		img {
			width: 100%;
			height: 100%;
		}
		// .diag-top {
		// 	@include sprite($diag-top);
		// 	@include mobile {
		// 		@include sprite($diag-top-mb);
		// 	}
		// }
		// .diag-bot {
		// 	@include sprite($diag-bot);
		// 	@include mobile {
		// 		@include sprite($diag-bot-mb);
		// 		z-index: 4;
		// 	}
		// }

		// .man {
		// 	@include sprite($man);
		// 	position: absolute;
		// 	bottom: 0;
		// 	left: 0;
		// 	z-index: 3;
		// }
	}

	.section__content {
		.title {
			position: absolute;
			top: 160px;
			left: 50%;
			translate: -50% 0;

			@include mobile {
				top: 120px;
			}
		}
		.people {
			@include sprite($people);
			position: absolute;
			top: 275px;
			right: 470px;

			@include mobile {
				z-index: 999;
				top: unset;
				right: unset;
				bottom: 0;
				left: 0;
			}
		}

		.news-bg {
			background-image: url("../images/news-bg.png");
			width: 1423px;
			height: 599px;
			position: absolute;
			left: 291px;
			bottom: 77px;
			z-index: 4;

			@include mobile {
				background-image: url("../images/news-bg-mb.png");
				width: 741px;
				height: 588px;
				left: 50%;
				bottom: 0;
				translate: -50% 0;
				z-index: 4;
			}

			.banner-swiper-container {
				position: absolute;
				top: 14px;
				left: 6px;
				background-image: url("../images/swiper-bg.png");
				width: 772px;
				height: 496px;

				@include mobile {
					width: 597px;
					height: 384px;
					left: 48%;
					top: -423px;
					translate: -50% 0;
				}

				.banner {
					@include sprite($banner);
					position: absolute;
					bottom: -5px;
					left: -42px;
					// display: flex;
					justify-content: center;
					align-items: center;
					z-index: 99;
					font-family: roboto;
					font-weight: 500;
					font-size: 24px;
					color: white;
					display: none;
					&.active {
						display: flex;
					}
				}

				.swiper {
					width: 709px;
					height: 436px;
					position: absolute;
					top: 38px;
					left: 35px;
					// overflow: hidden;

					a {
						display: block;
						width: 100%;
						height: 100%;
					}
					img {
						object-fit: cover;
						width: 100%;
						height: 100%;
					}

					@include mobile {
						width: 548px;
						height: 337px;
					}
				}

				.swiper-button {
					&::after {
						display: none;
					}
					&-prev {
						@include sprite($swiper-prev);
						right: unset;
						left: -10px;

						@include mobile {
							@include sprite($swiper-prev-mb);
							left: -53px;
						}
					}

					&-next {
						@include sprite($swiper-next);
						left: unset;
						right: -10px;
						@include mobile {
							@include sprite($swiper-next-mb);
							right: -73px;
						}
					}
				}

				.swiper-pagination {
					bottom: -40px;
					&-bullet {
						@include sprite($pagination);
						@include hoverLight(120%);
						transition: filter 200ms ease-in-out;
						background-color: transparent;
						border-radius: 0;
						opacity: 1;
						margin-left: 12px;
						&-active {
							@include sprite($pagination-selected);
						}
					}

					@include mobile {
						bottom: -32px;
					}
				}
			}

			.top-menu {
				$accent-color: #e7dcc3;
				position: absolute;
				top: 8px;
				right: 8px;
				width: 635px;
				height: 57px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				@include mobile {
					width: 96%;
					right: unset;
					left: 50%;
					translate: -50% 0;
				}

				.nav-container {
					flex-shrink: 0;
					display: flex;
					button {
						@include spriteBg;
						font-family: utm-talling;
						font-weight: 700;
						text-transform: uppercase;
						padding-bottom: 4px;
						font-size: 36px;
						flex-shrink: 0;
						min-width: 127px;
						height: 44px;
						padding-inline: 16px;
						color: $accent-color;
						&.active {
							background-image: url("../images/menu-selected.png");
							background-size: 100% 100%;
							color: black;
						}

						&:hover {
							color: #ed7f26;
							text-shadow: 0px 5px 2px #7d6e56, 0px 4px 2px #7d6e56, 0px 3px 2px #7d6e56,
								0px 2px 2px #7d6e56, 0px 1px 2px #7d6e56;
						}
					}
				}

				.search-form {
					@include sprite($search);
					display: flex;
					justify-content: space-between;
					align-items: center;
					input {
						height: 42px;
						width: 197px;
						padding: 8px;
						accent-color: $accent-color;
						color: $accent-color;
						font-size: 24px;
						font-family: roboto;
						font-weight: 400;
					}

					.btn-search {
						@include hoverDark();
						transition: filter 200ms ease-in-out;
						height: 40px;
						width: 100px;
						display: flex;
						justify-content: center;
						align-items: center;
						@include mobile {
							width: 100px;
							height: 50px;
						}
						.search-icon {
							@include sprite($search-icon);
						}
					}
				}
			}

			.news-container {
				width: 603px;
				height: 490px;
				position: absolute;
				right: 17px;
				bottom: 34px;
				@include mobile {
					bottom: unset;
					right: unset;
					top: 100px;
					left: 50%;
					translate: -50% 0;
					height: 400px;
					width: 638px;
				}

				.news-list {
					height: 378px;
					overflow: hidden;
					padding-right: 6px;
					z-index: 100;
					@include mobile {
						height: 312px;
					}

					.news {
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-family: roboto;
						font-weight: 400;
						font-size: 22px;
						height: 63px;
						border-bottom: 2px solid #d3c6ae;
						color: black;
						transition: all 0.1s ease-in-out;
						padding-inline: 4px;
						@include mobile {
							font-size: 24px;
						}
						&:hover {
							background-color: rgb(0, 0, 0, 0.02);
						}
						.left {
							display: flex;
							gap: 5px;
							.news-title {
								flex-shrink: 0;
								color: #c54100;
							}

							.news-text {
								flex-shrink: 0;
								width: 420px;
								overflow: hidden;
								@include mobile {
									width: 460px;
								}
							}
						}

						.right {
						}
					}

					& .news:last-child {
						border: none;
					}
				}

				.btn-more {
					@include sprite($btn-yellow);
					@include spriteBg;
					@include hoverLight;
					position: absolute;
					left: 50%;
					bottom: 0;
					translate: -50% 0;
					padding-bottom: 8px;
					color: black;
					font-family: utm-talling;
					font-weight: 700;
					font-size: 36px;
				}
			}
		}
	}
}

.th #block3 {
	.title {
		@include mobile {
			top: 102px;
			z-index: 100;
		}
	}
	.news-bg {
		.banner-swiper-container {
			.banner {
				font-family: sukhumvitset;
				font-weight: 400;
			}
		}
		.top-menu {
			.nav-container {
				button {
					font-family: sukhumvitset;
					font-weight: 700;
					font-size: 26px;
				}
			}
			.search-form {
				input {
					font-family: sukhumvitset;
					font-weight: 400;
				}
			}
		}

		.news-container {
			.news {
				font-family: sukhumvitset;
				font-weight: 400;
				font-size: 20px;
			}

			.btn-more {
				font-family: sukhumvitset;
				padding-bottom: 0p;
			}
		}
	}
}

.vn #block3 {
	.title {
		@include mobile {
			top: 110px;
		}
	}
	.news-bg {
		.top-menu {
			.nav-container {
				button {
					min-width: 100px;
				}
			}
			.search-form {
				input {
				}
			}
		}
	}
}

.tw #block3 {
	.title {
		@include mobile {
			top: 87px;
		}
	}
	.news-bg {
		.top-menu {
			.nav-container {
				button {
					font-family: chineseH;
					min-width: 100px;
				}
			}
			.search-form {
				input {
					font-family: chineseH;
				}
			}
		}

		.news-container {
			.news-list {
				.news .left {
					font-family: chineseH;
					font-size: 18px;
				}
			}

			.btn-more {
				font-family: chineseH;
				font-size: 36px;
			}
		}
	}
}
