// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$android-en-name: 'android-en';
$android-en-x: 144px;
$android-en-y: 98px;
$android-en-offset-x: -144px;
$android-en-offset-y: -98px;
$android-en-width: 144px;
$android-en-height: 46px;
$android-en-total-width: 432px;
$android-en-total-height: 377px;
$android-en-image: '../sprite.png';
$android-en: (144px, 98px, -144px, -98px, 144px, 46px, 432px, 377px, '../sprite.png', 'android-en', );
$android-id-name: 'android-id';
$android-id-x: 0px;
$android-id-y: 147px;
$android-id-offset-x: 0px;
$android-id-offset-y: -147px;
$android-id-width: 144px;
$android-id-height: 46px;
$android-id-total-width: 432px;
$android-id-total-height: 377px;
$android-id-image: '../sprite.png';
$android-id: (0px, 147px, 0px, -147px, 144px, 46px, 432px, 377px, '../sprite.png', 'android-id', );
$android-th-name: 'android-th';
$android-th-x: 144px;
$android-th-y: 147px;
$android-th-offset-x: -144px;
$android-th-offset-y: -147px;
$android-th-width: 144px;
$android-th-height: 46px;
$android-th-total-width: 432px;
$android-th-total-height: 377px;
$android-th-image: '../sprite.png';
$android-th: (144px, 147px, -144px, -147px, 144px, 46px, 432px, 377px, '../sprite.png', 'android-th', );
$android-tw-name: 'android-tw';
$android-tw-x: 0px;
$android-tw-y: 193px;
$android-tw-offset-x: 0px;
$android-tw-offset-y: -193px;
$android-tw-width: 144px;
$android-tw-height: 46px;
$android-tw-total-width: 432px;
$android-tw-total-height: 377px;
$android-tw-image: '../sprite.png';
$android-tw: (0px, 193px, 0px, -193px, 144px, 46px, 432px, 377px, '../sprite.png', 'android-tw', );
$apk-en-name: 'apk-en';
$apk-en-x: 144px;
$apk-en-y: 193px;
$apk-en-offset-x: -144px;
$apk-en-offset-y: -193px;
$apk-en-width: 144px;
$apk-en-height: 46px;
$apk-en-total-width: 432px;
$apk-en-total-height: 377px;
$apk-en-image: '../sprite.png';
$apk-en: (144px, 193px, -144px, -193px, 144px, 46px, 432px, 377px, '../sprite.png', 'apk-en', );
$apk-id-name: 'apk-id';
$apk-id-x: 0px;
$apk-id-y: 239px;
$apk-id-offset-x: 0px;
$apk-id-offset-y: -239px;
$apk-id-width: 144px;
$apk-id-height: 46px;
$apk-id-total-width: 432px;
$apk-id-total-height: 377px;
$apk-id-image: '../sprite.png';
$apk-id: (0px, 239px, 0px, -239px, 144px, 46px, 432px, 377px, '../sprite.png', 'apk-id', );
$apk-th-name: 'apk-th';
$apk-th-x: 144px;
$apk-th-y: 239px;
$apk-th-offset-x: -144px;
$apk-th-offset-y: -239px;
$apk-th-width: 144px;
$apk-th-height: 46px;
$apk-th-total-width: 432px;
$apk-th-total-height: 377px;
$apk-th-image: '../sprite.png';
$apk-th: (144px, 239px, -144px, -239px, 144px, 46px, 432px, 377px, '../sprite.png', 'apk-th', );
$apk-tw-name: 'apk-tw';
$apk-tw-x: 288px;
$apk-tw-y: 0px;
$apk-tw-offset-x: -288px;
$apk-tw-offset-y: 0px;
$apk-tw-width: 144px;
$apk-tw-height: 46px;
$apk-tw-total-width: 432px;
$apk-tw-total-height: 377px;
$apk-tw-image: '../sprite.png';
$apk-tw: (288px, 0px, -288px, 0px, 144px, 46px, 432px, 377px, '../sprite.png', 'apk-tw', );
$facebook-name: 'facebook';
$facebook-x: 144px;
$facebook-y: 331px;
$facebook-offset-x: -144px;
$facebook-offset-y: -331px;
$facebook-width: 46px;
$facebook-height: 41px;
$facebook-total-width: 432px;
$facebook-total-height: 377px;
$facebook-image: '../sprite.png';
$facebook: (144px, 331px, -144px, -331px, 46px, 41px, 432px, 377px, '../sprite.png', 'facebook', );
$group-name: 'group';
$group-x: 190px;
$group-y: 331px;
$group-offset-x: -190px;
$group-offset-y: -331px;
$group-width: 46px;
$group-height: 41px;
$group-total-width: 432px;
$group-total-height: 377px;
$group-image: '../sprite.png';
$group: (190px, 331px, -190px, -331px, 46px, 41px, 432px, 377px, '../sprite.png', 'group', );
$guide-en-name: 'guide-en';
$guide-en-x: 288px;
$guide-en-y: 46px;
$guide-en-offset-x: -288px;
$guide-en-offset-y: -46px;
$guide-en-width: 144px;
$guide-en-height: 46px;
$guide-en-total-width: 432px;
$guide-en-total-height: 377px;
$guide-en-image: '../sprite.png';
$guide-en: (288px, 46px, -288px, -46px, 144px, 46px, 432px, 377px, '../sprite.png', 'guide-en', );
$guide-id-name: 'guide-id';
$guide-id-x: 288px;
$guide-id-y: 92px;
$guide-id-offset-x: -288px;
$guide-id-offset-y: -92px;
$guide-id-width: 144px;
$guide-id-height: 46px;
$guide-id-total-width: 432px;
$guide-id-total-height: 377px;
$guide-id-image: '../sprite.png';
$guide-id: (288px, 92px, -288px, -92px, 144px, 46px, 432px, 377px, '../sprite.png', 'guide-id', );
$guide-th-name: 'guide-th';
$guide-th-x: 288px;
$guide-th-y: 138px;
$guide-th-offset-x: -288px;
$guide-th-offset-y: -138px;
$guide-th-width: 144px;
$guide-th-height: 46px;
$guide-th-total-width: 432px;
$guide-th-total-height: 377px;
$guide-th-image: '../sprite.png';
$guide-th: (288px, 138px, -288px, -138px, 144px, 46px, 432px, 377px, '../sprite.png', 'guide-th', );
$guide-tw-name: 'guide-tw';
$guide-tw-x: 288px;
$guide-tw-y: 184px;
$guide-tw-offset-x: -288px;
$guide-tw-offset-y: -184px;
$guide-tw-width: 144px;
$guide-tw-height: 46px;
$guide-tw-total-width: 432px;
$guide-tw-total-height: 377px;
$guide-tw-image: '../sprite.png';
$guide-tw: (288px, 184px, -288px, -184px, 144px, 46px, 432px, 377px, '../sprite.png', 'guide-tw', );
$guide-vi-name: 'guide-vi';
$guide-vi-x: 288px;
$guide-vi-y: 230px;
$guide-vi-offset-x: -288px;
$guide-vi-offset-y: -230px;
$guide-vi-width: 144px;
$guide-vi-height: 46px;
$guide-vi-total-width: 432px;
$guide-vi-total-height: 377px;
$guide-vi-image: '../sprite.png';
$guide-vi: (288px, 230px, -288px, -230px, 144px, 46px, 432px, 377px, '../sprite.png', 'guide-vi', );
$ios-en-name: 'ios-en';
$ios-en-x: 0px;
$ios-en-y: 285px;
$ios-en-offset-x: 0px;
$ios-en-offset-y: -285px;
$ios-en-width: 144px;
$ios-en-height: 46px;
$ios-en-total-width: 432px;
$ios-en-total-height: 377px;
$ios-en-image: '../sprite.png';
$ios-en: (0px, 285px, 0px, -285px, 144px, 46px, 432px, 377px, '../sprite.png', 'ios-en', );
$ios-id-name: 'ios-id';
$ios-id-x: 144px;
$ios-id-y: 285px;
$ios-id-offset-x: -144px;
$ios-id-offset-y: -285px;
$ios-id-width: 144px;
$ios-id-height: 46px;
$ios-id-total-width: 432px;
$ios-id-total-height: 377px;
$ios-id-image: '../sprite.png';
$ios-id: (144px, 285px, -144px, -285px, 144px, 46px, 432px, 377px, '../sprite.png', 'ios-id', );
$ios-th-name: 'ios-th';
$ios-th-x: 288px;
$ios-th-y: 285px;
$ios-th-offset-x: -288px;
$ios-th-offset-y: -285px;
$ios-th-width: 144px;
$ios-th-height: 46px;
$ios-th-total-width: 432px;
$ios-th-total-height: 377px;
$ios-th-image: '../sprite.png';
$ios-th: (288px, 285px, -288px, -285px, 144px, 46px, 432px, 377px, '../sprite.png', 'ios-th', );
$ios-tw-name: 'ios-tw';
$ios-tw-x: 0px;
$ios-tw-y: 331px;
$ios-tw-offset-x: 0px;
$ios-tw-offset-y: -331px;
$ios-tw-width: 144px;
$ios-tw-height: 46px;
$ios-tw-total-width: 432px;
$ios-tw-total-height: 377px;
$ios-tw-image: '../sprite.png';
$ios-tw: (0px, 331px, 0px, -331px, 144px, 46px, 432px, 377px, '../sprite.png', 'ios-tw', );
$tiktok-name: 'tiktok';
$tiktok-x: 236px;
$tiktok-y: 331px;
$tiktok-offset-x: -236px;
$tiktok-offset-y: -331px;
$tiktok-width: 46px;
$tiktok-height: 41px;
$tiktok-total-width: 432px;
$tiktok-total-height: 377px;
$tiktok-image: '../sprite.png';
$tiktok: (236px, 331px, -236px, -331px, 46px, 41px, 432px, 377px, '../sprite.png', 'tiktok', );
$topup-en-name: 'topup-en';
$topup-en-x: 0px;
$topup-en-y: 0px;
$topup-en-offset-x: 0px;
$topup-en-offset-y: 0px;
$topup-en-width: 144px;
$topup-en-height: 49px;
$topup-en-total-width: 432px;
$topup-en-total-height: 377px;
$topup-en-image: '../sprite.png';
$topup-en: (0px, 0px, 0px, 0px, 144px, 49px, 432px, 377px, '../sprite.png', 'topup-en', );
$topup-id-name: 'topup-id';
$topup-id-x: 0px;
$topup-id-y: 49px;
$topup-id-offset-x: 0px;
$topup-id-offset-y: -49px;
$topup-id-width: 144px;
$topup-id-height: 49px;
$topup-id-total-width: 432px;
$topup-id-total-height: 377px;
$topup-id-image: '../sprite.png';
$topup-id: (0px, 49px, 0px, -49px, 144px, 49px, 432px, 377px, '../sprite.png', 'topup-id', );
$topup-th-name: 'topup-th';
$topup-th-x: 144px;
$topup-th-y: 0px;
$topup-th-offset-x: -144px;
$topup-th-offset-y: 0px;
$topup-th-width: 144px;
$topup-th-height: 49px;
$topup-th-total-width: 432px;
$topup-th-total-height: 377px;
$topup-th-image: '../sprite.png';
$topup-th: (144px, 0px, -144px, 0px, 144px, 49px, 432px, 377px, '../sprite.png', 'topup-th', );
$topup-tw-name: 'topup-tw';
$topup-tw-x: 144px;
$topup-tw-y: 49px;
$topup-tw-offset-x: -144px;
$topup-tw-offset-y: -49px;
$topup-tw-width: 144px;
$topup-tw-height: 49px;
$topup-tw-total-width: 432px;
$topup-tw-total-height: 377px;
$topup-tw-image: '../sprite.png';
$topup-tw: (144px, 49px, -144px, -49px, 144px, 49px, 432px, 377px, '../sprite.png', 'topup-tw', );
$topup-vi-name: 'topup-vi';
$topup-vi-x: 0px;
$topup-vi-y: 98px;
$topup-vi-offset-x: 0px;
$topup-vi-offset-y: -98px;
$topup-vi-width: 144px;
$topup-vi-height: 49px;
$topup-vi-total-width: 432px;
$topup-vi-total-height: 377px;
$topup-vi-image: '../sprite.png';
$topup-vi: (0px, 98px, 0px, -98px, 144px, 49px, 432px, 377px, '../sprite.png', 'topup-vi', );
$spritesheet-width: 432px;
$spritesheet-height: 377px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($android-en, $android-id, $android-th, $android-tw, $apk-en, $apk-id, $apk-th, $apk-tw, $facebook, $group, $guide-en, $guide-id, $guide-th, $guide-tw, $guide-vi, $ios-en, $ios-id, $ios-th, $ios-tw, $tiktok, $topup-en, $topup-id, $topup-th, $topup-tw, $topup-vi, );
$spritesheet: (432px, 377px, '../sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
