@use "../../main/scss/mixin" as *;
@import "sprite.scss";

#faqs {
	.section__background {
		width: 100%;
		height: 100%;
		.bg-top {
			position: absolute;
			top: 0;
			left: 0;
		}

		.bg-bottom {
			position: absolute;
			bottom: 0;
			left: 0;
		}

		.bg-loop {
			width: 100%;
			height: 100%;
			background-image: url("../images/bg-loop.jpg");
			background-size: 100% 100%;

			@include mobile {
				background-image: url("../images/bg-loop-mb.jpg");
			}
		}
	}

	.section__content {
		.faq-container {
			width: 1139px;
			margin-bottom: 340px;
			margin-top: 201px;
			margin-inline: auto;
			@include mobile {
				width: 761px;
				margin-top: 299px;
				margin-bottom: 500px;
			}

			.title {
				text-align: center;
				font-size: 120px;
				font-family: utm-talling;
				font-weight: 700;

				@include mobile {
					position: relative;
					top: 36px;
				}
			}

			.accordion-container {
				width: 1023px;
				margin-inline: auto;
				font-size: 20px;
				font-family: roboto;
				margin-top: 31px;
				@include mobile {
					width: 660px;
				}

				.accordion {
					border-top: 2px solid #c7b8a1;
					padding-block: 24px;
					&-top {
						background-color: transparent;
						&:hover {
							background-color: rgb(0, 0, 0, 0.1);
						}
						transition: all 0.3s ease-in-out;
						cursor: pointer;
						width: 100%;
						display: flex;
						gap: 16px;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 24px;
						&-text {
							font-weight: 700;
							color: #cb6b2b;
							user-select: none;
						}
					}

					&-btn-toggle {
						flex-shrink: 0;
						@include sprite($arrow);
						rotate: 180deg;
						transition: rotate 0.2s ease-in-out;
					}

					&-bottom {
						display: flex;
						flex-direction: column;
						gap: 24px;
						max-height: 0;
						overflow: hidden;
						transition: all 0.3s ease-in-out;
					}

					&.active {
						.accordion-bottom {
							max-height: 99999px;
						}
						.accordion-btn-toggle {
							rotate: 0deg;
						}
					}
				}
			}
		}
	}
}

.th #faqs {
	font-family: sukhumvitset;
}

.tw #faqs {
	font-family: chineseH;
}
