// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$banner-name: 'banner';
$banner-x: 0px;
$banner-y: 0px;
$banner-offset-x: 0px;
$banner-offset-y: 0px;
$banner-width: 593px;
$banner-height: 65px;
$banner-total-width: 593px;
$banner-total-height: 287px;
$banner-image: '../sprite.png';
$banner: (0px, 0px, 0px, 0px, 593px, 65px, 593px, 287px, '../sprite.png', 'banner', );
$btn-yellow-name: 'btn-yellow';
$btn-yellow-x: 328px;
$btn-yellow-y: 65px;
$btn-yellow-offset-x: -328px;
$btn-yellow-offset-y: -65px;
$btn-yellow-width: 222px;
$btn-yellow-height: 70px;
$btn-yellow-total-width: 593px;
$btn-yellow-total-height: 287px;
$btn-yellow-image: '../sprite.png';
$btn-yellow: (328px, 65px, -328px, -65px, 222px, 70px, 593px, 287px, '../sprite.png', 'btn-yellow', );
$pagination-selected-name: 'pagination-selected';
$pagination-selected-x: 550px;
$pagination-selected-y: 96px;
$pagination-selected-offset-x: -550px;
$pagination-selected-offset-y: -96px;
$pagination-selected-width: 20px;
$pagination-selected-height: 18px;
$pagination-selected-total-width: 593px;
$pagination-selected-total-height: 287px;
$pagination-selected-image: '../sprite.png';
$pagination-selected: (550px, 96px, -550px, -96px, 20px, 18px, 593px, 287px, '../sprite.png', 'pagination-selected', );
$pagination-name: 'pagination';
$pagination-x: 570px;
$pagination-y: 96px;
$pagination-offset-x: -570px;
$pagination-offset-y: -96px;
$pagination-width: 20px;
$pagination-height: 18px;
$pagination-total-width: 593px;
$pagination-total-height: 287px;
$pagination-image: '../sprite.png';
$pagination: (570px, 96px, -570px, -96px, 20px, 18px, 593px, 287px, '../sprite.png', 'pagination', );
$people-name: 'people';
$people-x: 0px;
$people-y: 65px;
$people-offset-x: 0px;
$people-offset-y: -65px;
$people-width: 328px;
$people-height: 80px;
$people-total-width: 593px;
$people-total-height: 287px;
$people-image: '../sprite.png';
$people: (0px, 65px, 0px, -65px, 328px, 80px, 593px, 287px, '../sprite.png', 'people', );
$search-icon-name: 'search-icon';
$search-icon-x: 550px;
$search-icon-y: 65px;
$search-icon-offset-x: -550px;
$search-icon-offset-y: -65px;
$search-icon-width: 31px;
$search-icon-height: 31px;
$search-icon-total-width: 593px;
$search-icon-total-height: 287px;
$search-icon-image: '../sprite.png';
$search-icon: (550px, 65px, -550px, -65px, 31px, 31px, 593px, 287px, '../sprite.png', 'search-icon', );
$search-name: 'search';
$search-x: 0px;
$search-y: 145px;
$search-offset-x: 0px;
$search-offset-y: -145px;
$search-width: 258px;
$search-height: 49px;
$search-total-width: 593px;
$search-total-height: 287px;
$search-image: '../sprite.png';
$search: (0px, 145px, 0px, -145px, 258px, 49px, 593px, 287px, '../sprite.png', 'search', );
$swiper-next-mb-name: 'swiper-next-mb';
$swiper-next-mb-x: 0px;
$swiper-next-mb-y: 194px;
$swiper-next-mb-offset-x: 0px;
$swiper-next-mb-offset-y: -194px;
$swiper-next-mb-width: 53px;
$swiper-next-mb-height: 93px;
$swiper-next-mb-total-width: 593px;
$swiper-next-mb-total-height: 287px;
$swiper-next-mb-image: '../sprite.png';
$swiper-next-mb: (0px, 194px, 0px, -194px, 53px, 93px, 593px, 287px, '../sprite.png', 'swiper-next-mb', );
$swiper-next-name: 'swiper-next';
$swiper-next-x: 53px;
$swiper-next-y: 194px;
$swiper-next-offset-x: -53px;
$swiper-next-offset-y: -194px;
$swiper-next-width: 53px;
$swiper-next-height: 93px;
$swiper-next-total-width: 593px;
$swiper-next-total-height: 287px;
$swiper-next-image: '../sprite.png';
$swiper-next: (53px, 194px, -53px, -194px, 53px, 93px, 593px, 287px, '../sprite.png', 'swiper-next', );
$swiper-prev-mb-name: 'swiper-prev-mb';
$swiper-prev-mb-x: 106px;
$swiper-prev-mb-y: 194px;
$swiper-prev-mb-offset-x: -106px;
$swiper-prev-mb-offset-y: -194px;
$swiper-prev-mb-width: 53px;
$swiper-prev-mb-height: 93px;
$swiper-prev-mb-total-width: 593px;
$swiper-prev-mb-total-height: 287px;
$swiper-prev-mb-image: '../sprite.png';
$swiper-prev-mb: (106px, 194px, -106px, -194px, 53px, 93px, 593px, 287px, '../sprite.png', 'swiper-prev-mb', );
$swiper-prev-name: 'swiper-prev';
$swiper-prev-x: 159px;
$swiper-prev-y: 194px;
$swiper-prev-offset-x: -159px;
$swiper-prev-offset-y: -194px;
$swiper-prev-width: 53px;
$swiper-prev-height: 93px;
$swiper-prev-total-width: 593px;
$swiper-prev-total-height: 287px;
$swiper-prev-image: '../sprite.png';
$swiper-prev: (159px, 194px, -159px, -194px, 53px, 93px, 593px, 287px, '../sprite.png', 'swiper-prev', );
$spritesheet-width: 593px;
$spritesheet-height: 287px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($banner, $btn-yellow, $pagination-selected, $pagination, $people, $search-icon, $search, $swiper-next-mb, $swiper-next, $swiper-prev-mb, $swiper-prev, );
$spritesheet: (593px, 287px, '../sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
