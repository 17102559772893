@import "mixin";

.pc {
	display: block !important;
	@include mobile {
		display: none !important;
	}
}

.mb {
	display: none !important;
	@include mobile {
		display: block !important;
	}
}

.scrollwatch-pin {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 1px;
	height: 1px;
	background: transparent;
	pointer-events: none;
}

.wrapper {
	width: var(--width-desktop);
	// height: 100%;
	transform-origin: top left;
	@include mobile {
		width: var(--width-mobile);
	}

	&__content {
		overflow-x: hidden;
		overflow-y: hidden;
		width: var(--width-desktop);
		position: relative;
	}
}

.section {
	position: relative;
	display: block;
	width: var(--width-desktop);

	@include mobile {
		width: var(--width-mobile);
	}

	&__background {
		display: block;
		z-index: 0;
		width: 100%;
		height: 100%;
		// height: auto;
		// position: absolute;
		// top: 0px;
		// left: 0px;

		img,
		video {
			// width: 100%;
			// height: 100%;
			// object-fit: cover;
		}

		video {
			position: absolute;
			top: 0;
		}
	}
	&__content {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0px;
		left: 0px;
	}

	//

	&.autoHeight {
		.section__background {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
		}

		.section__content {
			display: block;
			position: relative;
		}
	}

	&--fixedHeight > &__background {
		position: static;
		display: block;
		width: 100%;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;

			@include mobile {
				height: auto;
			}
		}
	}

	&--fixedHeight > &__content {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0px;
		left: 0px;
	}

	// &__overlay {
	// 	display: block;
	// 	width: 100%;
	// 	height: 100%;
	// 	position: absolute;
	// 	top: 0px;
	// 	left: 0px;
	// 	@include backgroundGradient(rgba(#000, 0) 20%, rgba(#000, 0.8) 100%);
	// }
}

.floating {
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;

	z-index: 500;
	display: flex;
	align-items: center;
	justify-content: center;

	* {
		pointer-events: all;
	}

	&.floating-left {
		.float-scale {
			transform-origin: top left !important;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 100;
		}
	}

	&.floating-right {
		.float-scale {
			transform-origin: top right !important;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 100;
		}
	}

	&.floating-bottom {
		.float-scale {
			transform-origin: bottom left !important;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 100;
		}
	}

	.float__content {
		position: absolute;
	}
}

.outer {
	display: none;
}

.popup-fancybox {
	transform-origin: center center !important;

	.popup-inner {
		display: none;
		&.active {
			display: block;
		}
	}
}

@media (max-height: 900px) {
	.popup-fancybox {
		transform-origin: top center !important;
	}
}

// util class

.off {
	&,
	&:hover {
		filter: grayscale(100%);
		pointer-events: none;
	}
}

@keyframes screenShake {
	0% {
		translate: 0 0;
	}
	25% {
		translate: -2px 0;
	}
	50% {
		translate: 0 0;
	}
	75% {
		translate: -4px -2px;
	}
	100% {
		translate: 0 0;
	}
}
