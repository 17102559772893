// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$btn-yellow-name: 'btn-yellow';
$btn-yellow-x: 281px;
$btn-yellow-y: 306px;
$btn-yellow-offset-x: -281px;
$btn-yellow-offset-y: -306px;
$btn-yellow-width: 177px;
$btn-yellow-height: 57px;
$btn-yellow-total-width: 492px;
$btn-yellow-total-height: 445px;
$btn-yellow-image: '../sprite.png';
$btn-yellow: (281px, 306px, -281px, -306px, 177px, 57px, 492px, 445px, '../sprite.png', 'btn-yellow', );
$coins-name: 'coins';
$coins-x: 407px;
$coins-y: 212px;
$coins-offset-x: -407px;
$coins-offset-y: -212px;
$coins-width: 76px;
$coins-height: 62px;
$coins-total-width: 492px;
$coins-total-height: 445px;
$coins-image: '../sprite.png';
$coins: (407px, 212px, -407px, -212px, 76px, 62px, 492px, 445px, '../sprite.png', 'coins', );
$game-icon-mb-name: 'game-icon-mb';
$game-icon-mb-x: 281px;
$game-icon-mb-y: 84px;
$game-icon-mb-offset-x: -281px;
$game-icon-mb-offset-y: -84px;
$game-icon-mb-width: 128px;
$game-icon-mb-height: 128px;
$game-icon-mb-total-width: 492px;
$game-icon-mb-total-height: 445px;
$game-icon-mb-image: '../sprite.png';
$game-icon-mb: (281px, 84px, -281px, -84px, 128px, 128px, 492px, 445px, '../sprite.png', 'game-icon-mb', );
$game-icon-tw-name: 'game-icon-tw';
$game-icon-tw-x: 281px;
$game-icon-tw-y: 0px;
$game-icon-tw-offset-x: -281px;
$game-icon-tw-offset-y: 0px;
$game-icon-tw-width: 211px;
$game-icon-tw-height: 84px;
$game-icon-tw-total-width: 492px;
$game-icon-tw-total-height: 445px;
$game-icon-tw-image: '../sprite.png';
$game-icon-tw: (281px, 0px, -281px, 0px, 211px, 84px, 492px, 445px, '../sprite.png', 'game-icon-tw', );
$game-icon-name: 'game-icon';
$game-icon-x: 281px;
$game-icon-y: 212px;
$game-icon-offset-x: -281px;
$game-icon-offset-y: -212px;
$game-icon-width: 126px;
$game-icon-height: 94px;
$game-icon-total-width: 492px;
$game-icon-total-height: 445px;
$game-icon-image: '../sprite.png';
$game-icon: (281px, 212px, -281px, -212px, 126px, 94px, 492px, 445px, '../sprite.png', 'game-icon', );
$language-dropdown-name: 'language-dropdown';
$language-dropdown-x: 0px;
$language-dropdown-y: 0px;
$language-dropdown-offset-x: 0px;
$language-dropdown-offset-y: 0px;
$language-dropdown-width: 281px;
$language-dropdown-height: 445px;
$language-dropdown-total-width: 492px;
$language-dropdown-total-height: 445px;
$language-dropdown-image: '../sprite.png';
$language-dropdown: (0px, 0px, 0px, 0px, 281px, 445px, 492px, 445px, '../sprite.png', 'language-dropdown', );
$language-icon-name: 'language-icon';
$language-icon-x: 409px;
$language-icon-y: 84px;
$language-icon-offset-x: -409px;
$language-icon-offset-y: -84px;
$language-icon-width: 82px;
$language-icon-height: 82px;
$language-icon-total-width: 492px;
$language-icon-total-height: 445px;
$language-icon-image: '../sprite.png';
$language-icon: (409px, 84px, -409px, -84px, 82px, 82px, 492px, 445px, '../sprite.png', 'language-icon', );
$present-name: 'present';
$present-x: 281px;
$present-y: 363px;
$present-offset-x: -281px;
$present-offset-y: -363px;
$present-width: 55px;
$present-height: 66px;
$present-total-width: 492px;
$present-total-height: 445px;
$present-image: '../sprite.png';
$present: (281px, 363px, -281px, -363px, 55px, 66px, 492px, 445px, '../sprite.png', 'present', );
$spritesheet-width: 492px;
$spritesheet-height: 445px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($btn-yellow, $coins, $game-icon-mb, $game-icon-tw, $game-icon, $language-dropdown, $language-icon, $present, );
$spritesheet: (492px, 445px, '../sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
