@import "mixin";

#shootScreen {
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
}

#block1 {
	height: 1023px;

	@include mobile {
		width: var(--width-mobile);
		height: 1199px;
	}
}

#block2 {
	height: 1024px;

	@include mobile {
		width: var(--width-mobile);
		height: 1200px;
	}
}

#block3 {
	height: 1024px;

	@include mobile {
		width: var(--width-mobile);
		height: 1200px;
	}
}

#block4 {
	height: 1024px;

	@include mobile {
		width: var(--width-mobile);
		height: 1200px;
	}
}

#block5 {
	height: 1024px;

	@include mobile {
		width: var(--width-mobile);
		height: 981px;
	}
}

#footer {
	height: 461px;

	@include mobile {
		width: var(--width-mobile);
		height: 418px + 113px;
		background-color: #282828;
	}
}

:is(.tw, .vn) #footer {
	height: 688px;
	background-color: #282828;
	@include mobile {
		width: var(--width-mobile);
		height: 573px + 113px;
		background-color: #282828;
	}
}

#faqs {
	min-height: 1404px;
	height: auto;
	@include mobile {
		width: var(--width-mobile);
		min-height: 1711px;
	}
}

#details {
	min-height: 1382px;
	height: fit-content;

	@include mobile {
		width: var(--width-mobile);
		min-height: 1515px;
	}
}

#guide {
	height: 1554px;

	@include mobile {
		width: var(--width-mobile);
	}
}

// custom

.diag {
	&-top {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 2;
	}

	&-bot {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 2;
	}
}
