// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$btn-play-name: 'btn-play';
$btn-play-x: 172px;
$btn-play-y: 0px;
$btn-play-offset-x: -172px;
$btn-play-offset-y: 0px;
$btn-play-width: 90px;
$btn-play-height: 83px;
$btn-play-total-width: 262px;
$btn-play-total-height: 245px;
$btn-play-image: '../sprite.png';
$btn-play: (172px, 0px, -172px, 0px, 90px, 83px, 262px, 245px, '../sprite.png', 'btn-play', );
$pagination-selected-name: 'pagination-selected';
$pagination-selected-x: 172px;
$pagination-selected-y: 83px;
$pagination-selected-offset-x: -172px;
$pagination-selected-offset-y: -83px;
$pagination-selected-width: 20px;
$pagination-selected-height: 18px;
$pagination-selected-total-width: 262px;
$pagination-selected-total-height: 245px;
$pagination-selected-image: '../sprite.png';
$pagination-selected: (172px, 83px, -172px, -83px, 20px, 18px, 262px, 245px, '../sprite.png', 'pagination-selected', );
$pagination-name: 'pagination';
$pagination-x: 192px;
$pagination-y: 83px;
$pagination-offset-x: -192px;
$pagination-offset-y: -83px;
$pagination-width: 20px;
$pagination-height: 18px;
$pagination-total-width: 262px;
$pagination-total-height: 245px;
$pagination-image: '../sprite.png';
$pagination: (192px, 83px, -192px, -83px, 20px, 18px, 262px, 245px, '../sprite.png', 'pagination', );
$swiper-next-mb-name: 'swiper-next-mb';
$swiper-next-mb-x: 0px;
$swiper-next-mb-y: 152px;
$swiper-next-mb-offset-x: 0px;
$swiper-next-mb-offset-y: -152px;
$swiper-next-mb-width: 53px;
$swiper-next-mb-height: 93px;
$swiper-next-mb-total-width: 262px;
$swiper-next-mb-total-height: 245px;
$swiper-next-mb-image: '../sprite.png';
$swiper-next-mb: (0px, 152px, 0px, -152px, 53px, 93px, 262px, 245px, '../sprite.png', 'swiper-next-mb', );
$swiper-next-name: 'swiper-next';
$swiper-next-x: 0px;
$swiper-next-y: 0px;
$swiper-next-offset-x: 0px;
$swiper-next-offset-y: 0px;
$swiper-next-width: 86px;
$swiper-next-height: 152px;
$swiper-next-total-width: 262px;
$swiper-next-total-height: 245px;
$swiper-next-image: '../sprite.png';
$swiper-next: (0px, 0px, 0px, 0px, 86px, 152px, 262px, 245px, '../sprite.png', 'swiper-next', );
$swiper-prev-mb-name: 'swiper-prev-mb';
$swiper-prev-mb-x: 53px;
$swiper-prev-mb-y: 152px;
$swiper-prev-mb-offset-x: -53px;
$swiper-prev-mb-offset-y: -152px;
$swiper-prev-mb-width: 53px;
$swiper-prev-mb-height: 93px;
$swiper-prev-mb-total-width: 262px;
$swiper-prev-mb-total-height: 245px;
$swiper-prev-mb-image: '../sprite.png';
$swiper-prev-mb: (53px, 152px, -53px, -152px, 53px, 93px, 262px, 245px, '../sprite.png', 'swiper-prev-mb', );
$swiper-prev-name: 'swiper-prev';
$swiper-prev-x: 86px;
$swiper-prev-y: 0px;
$swiper-prev-offset-x: -86px;
$swiper-prev-offset-y: 0px;
$swiper-prev-width: 86px;
$swiper-prev-height: 152px;
$swiper-prev-total-width: 262px;
$swiper-prev-total-height: 245px;
$swiper-prev-image: '../sprite.png';
$swiper-prev: (86px, 0px, -86px, 0px, 86px, 152px, 262px, 245px, '../sprite.png', 'swiper-prev', );
$spritesheet-width: 262px;
$spritesheet-height: 245px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($btn-play, $pagination-selected, $pagination, $swiper-next-mb, $swiper-next, $swiper-prev-mb, $swiper-prev, );
$spritesheet: (262px, 245px, '../sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
