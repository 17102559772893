@import "../../main/scss/mixin";
@import "sprite.scss";

#block2 {
	.section__background {
		img {
			width: 100%;
			height: 100%;
		}
		.diag-top {
			@include sprite($diag-top);

			@include mobile {
				@include sprite($diag-top-mb);
			}
		}
		.diag-bot {
			@include sprite($diag-bot);
			@include mobile {
				@include sprite($diag-bot-mb);
			}
		}

		.man {
			@include sprite($man);
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 3;

			@include mobile {
				@include sprite($man-mb);
				bottom: 410px;
			}
		}
	}

	.section__content {
		.decor-line {
			@include sprite($line);
			position: absolute;
			top: 63px;
			left: 215px;
			z-index: 3;
		}
		.video-img {
			width: 598px;
			height: 337px;
			position: absolute;
			top: 428px;
			left: 428px;

			@include mobile {
				width: 500px;
				height: 282px;
				object-fit: cover;
				left: 182px;
				top: 321px;
			}
		}
		.arcade {
			background-image: url("../images/video.png");
			width: 813px;
			height: 967px;
			position: absolute;
			bottom: 0;
			left: 305px;
			z-index: 1;
			background-size: 100% 100%;

			@include mobile {
				top: 40px;
				bottom: unset;
				left: 53%;
				translate: -50% 0;
				width: 653px;
				height: 776px;
			}

			.title {
				position: absolute;
				top: 100px;
				left: 50%;
				translate: -50% 0;

				@include mobile {
					top: 76px;
				}
			}

			.btn-play {
				@include sprite($btn-play);
				@include spriteImg;
				@include hoverLight();
				@include hoverBig;
				transition: filter 200ms ease-in-out, scale 200ms ease-in-out;
				position: absolute;
				bottom: 401px;
				left: 367px;

				@include mobile {
					left: 51%;
					bottom: 325px;
					translate: -50% 0;
				}
			}
		}

		.text-container {
			width: 490px;
			//height: 560px;
			position: absolute;
			top: 382px;
			right: 418px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			@include mobile {
				width: var(--width-mobile);
				background-image: url("../images/gradient.png");
				background-size: 100% 100%;
				height: 325px;
				right: unset;
				top: unset;
				left: 0;
				bottom: 174px;
				align-items: center;
				z-index: 999;
			}

			.heading {
				@include sprite($title);
				flex-shrink: 0;
			}

			.content {
				text-align: left;
				flex-shrink: 0;
				width: 100%;
				height: 415px;
				margin-top: 22px;
				margin-bottom: 30px;
				display: flex;
				flex-direction: column;
				gap: 15px;
				overflow: hidden;
				@include mobile {
					height: 245px;
					margin-top: 17px;
					margin-bottom: 18px;
					padding-inline: 64px;
					overflow-y: auto ;
				}

				p {
					font-family: roboto;
					font-weight: 300;
					font-size: 24px;
					line-height: 33px;
					color: #cdc1a5;

					@include mobile {
						//font-size: 24px;
						//line-height: 34px;
					}
				}
			}

			.btn-more {
				flex-shrink: 0;
				@include sprite($btn-yellow);
				@include spriteBg;
				@include hoverLight();
				font-family: utm-talling;
				font-weight: 700;
				padding-bottom: 8px;
				font-size: 36px;
				color: #473021;

				@include mobile {
					@include sprite($btn-yellow-mb);
					padding: 0;
				}
			}
		}
	}
}

.tw #block2 {
	.section__content {
		.title {
			@include mobile {
				top: 57px;
			}
		}
		.text-container {
			.content {
				p {
					font-family: chineseH;
					//font-size: 18px;
					//line-height: 24px;
				}
			}
			.btn-more {
				font-family: chineseH;
				font-size: 36px;
				padding-bottom: 0;
			}

			.heading {
				@include sprite($title-tw);
			}
		}
	}
}
.th #block2 {
	.section__content {
		
		.text-container {
			.content {
				p {
					font-family: sukhumvitset;
					font-size: 25px;
				}
			}
		}
	}
}
