// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$btn-play-name: 'btn-play';
$btn-play-x: 1592px;
$btn-play-y: 329px;
$btn-play-offset-x: -1592px;
$btn-play-offset-y: -329px;
$btn-play-width: 56px;
$btn-play-height: 56px;
$btn-play-total-width: 1662px;
$btn-play-total-height: 1112px;
$btn-play-image: '../sprite.png';
$btn-play: (1592px, 329px, -1592px, -329px, 56px, 56px, 1662px, 1112px, '../sprite.png', 'btn-play', );
$btn-yellow-mb-name: 'btn-yellow-mb';
$btn-yellow-mb-x: 1413px;
$btn-yellow-mb-y: 464px;
$btn-yellow-mb-offset-x: -1413px;
$btn-yellow-mb-offset-y: -464px;
$btn-yellow-mb-width: 222px;
$btn-yellow-mb-height: 70px;
$btn-yellow-mb-total-width: 1662px;
$btn-yellow-mb-total-height: 1112px;
$btn-yellow-mb-image: '../sprite.png';
$btn-yellow-mb: (1413px, 464px, -1413px, -464px, 222px, 70px, 1662px, 1112px, '../sprite.png', 'btn-yellow-mb', );
$btn-yellow-name: 'btn-yellow';
$btn-yellow-x: 1413px;
$btn-yellow-y: 534px;
$btn-yellow-offset-x: -1413px;
$btn-yellow-offset-y: -534px;
$btn-yellow-width: 222px;
$btn-yellow-height: 70px;
$btn-yellow-total-width: 1662px;
$btn-yellow-total-height: 1112px;
$btn-yellow-image: '../sprite.png';
$btn-yellow: (1413px, 534px, -1413px, -534px, 222px, 70px, 1662px, 1112px, '../sprite.png', 'btn-yellow', );
$diag-bot-mb-name: 'diag-bot-mb';
$diag-bot-mb-x: 0px;
$diag-bot-mb-y: 857px;
$diag-bot-mb-offset-x: 0px;
$diag-bot-mb-offset-y: -857px;
$diag-bot-mb-width: 768px;
$diag-bot-mb-height: 255px;
$diag-bot-mb-total-width: 1662px;
$diag-bot-mb-total-height: 1112px;
$diag-bot-mb-image: '../sprite.png';
$diag-bot-mb: (0px, 857px, 0px, -857px, 768px, 255px, 1662px, 1112px, '../sprite.png', 'diag-bot-mb', );
$diag-bot-name: 'diag-bot';
$diag-bot-x: 445px;
$diag-bot-y: 350px;
$diag-bot-offset-x: -445px;
$diag-bot-offset-y: -350px;
$diag-bot-width: 718px;
$diag-bot-height: 309px;
$diag-bot-total-width: 1662px;
$diag-bot-total-height: 1112px;
$diag-bot-image: '../sprite.png';
$diag-bot: (445px, 350px, -445px, -350px, 718px, 309px, 1662px, 1112px, '../sprite.png', 'diag-bot', );
$diag-top-mb-name: 'diag-top-mb';
$diag-top-mb-x: 768px;
$diag-top-mb-y: 857px;
$diag-top-mb-offset-x: -768px;
$diag-top-mb-offset-y: -857px;
$diag-top-mb-width: 356px;
$diag-top-mb-height: 120px;
$diag-top-mb-total-width: 1662px;
$diag-top-mb-total-height: 1112px;
$diag-top-mb-image: '../sprite.png';
$diag-top-mb: (768px, 857px, -768px, -857px, 356px, 120px, 1662px, 1112px, '../sprite.png', 'diag-top-mb', );
$diag-top-name: 'diag-top';
$diag-top-x: 0px;
$diag-top-y: 0px;
$diag-top-offset-x: 0px;
$diag-top-offset-y: 0px;
$diag-top-width: 1413px;
$diag-top-height: 350px;
$diag-top-total-width: 1662px;
$diag-top-total-height: 1112px;
$diag-top-image: '../sprite.png';
$diag-top: (0px, 0px, 0px, 0px, 1413px, 350px, 1662px, 1112px, '../sprite.png', 'diag-top', );
$line-name: 'line';
$line-x: 1413px;
$line-y: 329px;
$line-offset-x: -1413px;
$line-offset-y: -329px;
$line-width: 179px;
$line-height: 135px;
$line-total-width: 1662px;
$line-total-height: 1112px;
$line-image: '../sprite.png';
$line: (1413px, 329px, -1413px, -329px, 179px, 135px, 1662px, 1112px, '../sprite.png', 'line', );
$man-mb-name: 'man-mb';
$man-mb-x: 1413px;
$man-mb-y: 0px;
$man-mb-offset-x: -1413px;
$man-mb-offset-y: 0px;
$man-mb-width: 249px;
$man-mb-height: 329px;
$man-mb-total-width: 1662px;
$man-mb-total-height: 1112px;
$man-mb-image: '../sprite.png';
$man-mb: (1413px, 0px, -1413px, 0px, 249px, 329px, 1662px, 1112px, '../sprite.png', 'man-mb', );
$man-name: 'man';
$man-x: 0px;
$man-y: 350px;
$man-offset-x: 0px;
$man-offset-y: -350px;
$man-width: 445px;
$man-height: 507px;
$man-total-width: 1662px;
$man-total-height: 1112px;
$man-image: '../sprite.png';
$man: (0px, 350px, 0px, -350px, 445px, 507px, 1662px, 1112px, '../sprite.png', 'man', );
$title-tw-name: 'title-tw';
$title-tw-x: 445px;
$title-tw-y: 659px;
$title-tw-offset-x: -445px;
$title-tw-offset-y: -659px;
$title-tw-width: 523px;
$title-tw-height: 84px;
$title-tw-total-width: 1662px;
$title-tw-total-height: 1112px;
$title-tw-image: '../sprite.png';
$title-tw: (445px, 659px, -445px, -659px, 523px, 84px, 1662px, 1112px, '../sprite.png', 'title-tw', );
$title-name: 'title';
$title-x: 1124px;
$title-y: 857px;
$title-offset-x: -1124px;
$title-offset-y: -857px;
$title-width: 287px;
$title-height: 119px;
$title-total-width: 1662px;
$title-total-height: 1112px;
$title-image: '../sprite.png';
$title: (1124px, 857px, -1124px, -857px, 287px, 119px, 1662px, 1112px, '../sprite.png', 'title', );
$spritesheet-width: 1662px;
$spritesheet-height: 1112px;
$spritesheet-image: '../sprite.png';
$spritesheet-sprites: ($btn-play, $btn-yellow-mb, $btn-yellow, $diag-bot-mb, $diag-bot, $diag-top-mb, $diag-top, $line, $man-mb, $man, $title-tw, $title, );
$spritesheet: (1662px, 1112px, '../sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
